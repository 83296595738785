import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on load
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/login`,
        formData
      );
      localStorage.setItem("token", response.data.token);
      navigate("/dashboard");
    } catch (err) {
      setError("Invalid username or password");
    }
  };

  //   <div className="login">
  //   <form onSubmit={handleSubmit} className="login-content">
  //     <h2>Admin Login</h2>
  //     <input
  //       type="text"
  //       className="form-control"
  //       name="username"
  //       placeholder="Username"
  //       value={formData.username}
  //       onChange={handleChange}
  //     />
  //     <input
  //       type="password"
  //       className="form-control"
  //       name="password"
  //       placeholder="Password"
  //       value={formData.password}
  //       onChange={handleChange}
  //     />
  //     {error && <p className="error">{error}</p>}
  //     <button type="submit" className="redBtn">
  //       Login
  //     </button>
  //   </form>
  // </div>

  return (
    <>
      <div className="InnerPageBody p-0" style={{ marginTop: "100px" }}>
        <div className="contactContent">
          <div className="right">
            <div className="contactForm">
              <h1></h1>

              <h3>Enter the username and password</h3>

              <form onSubmit={handleSubmit}>
                <div className="column">
                  <div className="col-6 col-lg-6">
                    <input
                      className="form-control"
                      type="text"
                      name="username"
                      placeholder="Username"
                      required
                      value={formData.username}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-6 col-lg-6">
                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      placeholder="Password"
                      required
                      value={formData.password}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/* <!-- Submit button --> */}
                <div className="row">
                  <div className="col-12 col-lg-12">
                    <button className="redBtn" type="submit">
                      Login
                    </button>
                  </div>
                </div>
              </form>
              {/* <!-- Form ends here --> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
