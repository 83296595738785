import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    // Scroll to top only if there's no hash fragment
    if (!hash) {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]); // Include hash to satisfy ESLint, but logic prevents unwanted scrolling

  return null;
};

export default ScrollToTop;
