import React, { useEffect } from "react";
const Career = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on load
  }, []);
  return (
    <>
      <div className="InnerPageBanner"  style={{ marginTop: "100px" }}>
        <div className="InnerBannerTitle">
          Career
          <p>Coming Soon... </p>
        </div>
      </div>

      <div className="InnerPageBody p-0">
        <div className="overviewContent">
          <h3 className="my-5 comingSoon">Coming Soon...</h3>
        </div>
      </div>
    </>
  );
};

export default Career;
