import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import blister from "../assets/images/blsterImg.png";
import jars from "../assets/images/Jars.png";
import blisterThumb from "../assets/images/blsterImg_Thumb.png";

const PackagingServices = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("BlisterPacking");
  const [isMenuCollapsed, setMenuCollapsed] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on load
  }, []);

  console.log("coming here!")

  useEffect(() => {
    // Set initial tab based on URL path
    const path = location.pathname.split("/").pop();
    if (path === "blister-packing") {
      setActiveTab("BlisterPacking");
    } else if (path === "powder-filling") {
      setActiveTab("PowderFilling");
    } else if (path === "bottling") {
      setActiveTab("Bottling");
    }
  }, [location]);

  useEffect(() => {
    // Set initial tab based on URL hash
    const hash = location.hash;
    let tab_id = "";

    if (hash === "#blister-packing") {
      setActiveTab("BlisterPacking");
      tab_id = "BlisterPacking";
    } else if (hash === "#powder-filling") {
      setActiveTab("PowderFilling");
      tab_id = "PowderFilling";
    } else if (hash === "#bottling") {
      setActiveTab("Bottling");
      tab_id = "Bottling";
    }

    if (tab_id) {
      const element = document.getElementById("tabs");
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 100);
      }
    }
  }, [location.hash]);

  const tabs = [
    { id: "BlisterPacking", title: "Blister packing" },
    { id: "PowderFilling", title: "Powder filling" },
    { id: "Bottling", title: "Bottling" },
  ];

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
    switch (tabId) {
      case "BlisterPacking":
        navigate("/packagingservices/#blister-packing");
        break;
      case "PowderFilling":
        navigate("/packagingservices/#powder-filling");
        break;
      case "Bottling":
        navigate("/packagingservices/#bottling");
        break;
      default:
        break;
    }
    setMenuCollapsed(true);
  };

  const toggleMenu = () => {
    setMenuCollapsed(!isMenuCollapsed);
  };

  const renderTabContent = () => {
    const tabContents = {
      BlisterPacking: (
        <div className="InnerPageTabsContent">
          <div className="row mb-5">
            <div className="col-12 col-md-6 mb-5">
              <h2 className="text-start">Blister packing</h2>
              <p>
                Our packaging services provide customers with an attractive
                product ready for the marketplace. We utilize an automated,
                state-of-the-art, fully integrated bottling line to guarantee
                the highest quality products are manufactured consistently,
                batch after batch.
              </p>
              <p>
                Our broad packaging capabilities include individual lines with
                electronic tablet and capsule counters, Blister packing, powder
                fillers for jars and cans.
              </p>
            </div>
            <div className="col-12 col-md-6">
              <img
                src={blister}
                className="blendingImage2"
                alt="Blister Packing"
              />
            </div>
          </div>

          <div className="row mt-5">
            {/* <div className="col-12 col-md-6" style={{ position: "relative", paddingBottom: "56.25%", height: "0" }}>
              <iframe
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
                src="https://www.youtube.com/embed/Nh_UywRlErQ?si=cMeonS6Z_n-mnuzF"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div> */}
            <div className="col-12 col-md-6">
              <h4>We have experience in handling</h4>
              <ul className="BlisterList">
                <li>Gelatin Capsules</li>
                <li>Soft Gel Capsules</li>
                <li>Tablets</li>
                <li>Caplets</li>
              </ul>
            </div>
          </div>
        </div>
      ),
      PowderFilling: (
        <div className="InnerPageTabsContent">
          <div className="row mb-5">
            <div className="col-12 col-md-6">
              <h2 className="text-start">Powder filling</h2>
              <p>
                Our packaging services provide customers with an attractive
                product ready for the marketplace. We utilize an automated,
                state-of-the-art, fully integrated bottling line to guarantee
                the highest quality products are manufactured consistently,
                batch after batch.
              </p>
              <p>
                Our broad packaging capabilities include individual lines with
                electronic tablet and capsule counters, Blister packing, powder
                fillers for jars and cans.
              </p>
            </div>
            <div className="col-12 col-md-6">
              <img src={jars} alt="" className="blendingImage2" />
            </div>
          </div>
          <div className="row mt-5">
            {/* <div className="col-12 col-md-6" style={{ position: "relative", paddingBottom: "56.25%", height: "0" }}>
              <iframe
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
                src="https://www.youtube.com/embed/w2H4q1G8q0M?si=ztZLKdH5KJBI5qp1" 
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div> */}
            <div className="col-12 col-md-6">
              <h3 className="text-start">We have experience in handling:</h3>
              <ul className="TabletsList fw-bolder">
                <li>Flexible Pouches/Sachets</li>
                <li>Filling in Jars</li>
                <li>Zipper Pouches</li>
              </ul>
            </div>
          </div>
        </div>
      ),
      Bottling: (
        <div className="InnerPageTabsContent">
          <div className="row mb-5">
            <div className="col-12 col-md-6">
              <h2 className="text-start">Bottling</h2>
              <p>
                We have dedicated clean rooms with state-of-the-art machinery
                that precisely measure Capsules, Tablets and Softgels into
                bottles. Our facility offers a number of clean rooms which have
                completely integrated systems, including bottle counting,
                closure, heat sealing and labelling.
              </p>
              <p>
                We offer induction sealing, neck banding, and full body sleeves
                as well as a range of labeling and imprinting options.
              </p>
            </div>
            <div className="col-12 col-md-6">
              <img src={blisterThumb} alt="" className="blendingImage2" />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 col-md-6">
              <h3 className="text-start">
                We can accommodate a wide variety of bottles and closures.
              </h3>
              <ul className="TabletsList fw-bolder">
                <li>Bottles (PET/HDPE/PE)</li>
                <li>Glass</li>
                <li>Bulk Bagged</li>
                <li>Bulk Boxed</li>
              </ul>
            </div>
          </div>
        </div>
      ),
    };

    return tabContents[activeTab];
  };

  return (
    <>
      <div className="manufactureBanner">
        <div className="container">
          <h2>Packaging services</h2>
          <p>
            <i className="icon-lineArrowBottom"></i>
          </p>
          <p>
            At Pharmaline, we offer comprehensive packaging solutions designed
            to meet the needs of the healthcare and cosmetics industries. Our
            state-of-the-art packaging equipment is housed in a hygienic, fully
            equipped facility, ensuring that your products are packaged
            efficiently and to the highest quality standards.
          </p>
          <h3>Packaging services</h3>
          <p>
            <strong>Advanced Technology:</strong> We utilize automated,
            state-of-the-art packaging lines to ensure consistency and
            precision.
          </p>
          <p>
            <strong>Versatility:</strong> Our packaging capabilities include a
            wide range of formats, from blister packs to bottles, jars, and
            cans.
          </p>
          <p>
            <strong>Quality Assurance:</strong> Every product is packaged with
            care, ensuring it is market-ready and visually appealing.
          </p>
          <p>
            <strong>Customization:</strong> We tailor our packaging solutions to
            meet your specific product and branding requirements.
          </p>
          <h3>Our Packaging Capabilities</h3>
          <p>We specialize in the following packaging services:</p>
        </div>
      </div>

      <div className="InnerPageBody p-0" id="tabs">
        <div className="prodctUnits container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="manufactureLinks">
                <div id="tabs" className="innerPageTabs">
                  <ul
                    className="nav nav-tabs MSTabs mobile-tabs container"
                    role="tablist"
                    id="topFixedTabs"
                    onClick={toggleMenu}
                  >
                    {tabs.map((tab) => (
                      <li
                        key={tab.id}
                        className={`nav-item ${
                          isMenuCollapsed && activeTab !== tab.id
                            ? "d-none d-md-block"
                            : ""
                        } ${activeTab === tab.id ? "active" : ""}`}
                        role="tab"
                      >
                        <a
                          className={`nav-link ${
                            activeTab === tab.id ? "active" : ""
                          }`}
                          data-bs-toggle="tab"
                          href={`#${tab.id}`}
                          aria-controls={tab.id}
                          role="tab"
                          onClick={(e) => {e.preventDefault(); handleTabChange(tab.id)}}
                        >
                          {tab.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="tab-content">
                  <div
                    className={`tab-pane fade tab-style ml-3 mt-3 ${
                      activeTab ? "active show" : ""
                    }`}
                    id={activeTab}
                    role="tabpanel"
                  >
                    {renderTabContent()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackagingServices;
