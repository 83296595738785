import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "../css/fonts/IconFonts/css/pharmalinefonts.css";
import blisterPacking from "../assets/images/blisterpacking_02.jpg";
import powderFilling from "../assets/images/powderfilling.jpg";
import bottlePacking from "../assets/images/bottling.jpg";

const PackaingMain = () => {
  const [activeTab, setActiveTab] = useState("BlisterPackaging");
  const [isMenuCollapsed, setMenuCollapsed] = useState(true);

  const tabs = [
    { id: "BlisterPackaging", title: "Blister Packaging" },
    { id: "PowderFilling", title: "Powder Filling" },
    { id: "BottlePacking", title: "Bottle Packing" },

  ];

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
    setMenuCollapsed(true);
  };

  const toggleMenu = () => {
    setMenuCollapsed(!isMenuCollapsed);
  };

  const renderTabContent = () => {
    const tabContents = {
      "BlisterPackaging": (
        <div className="tab-content">
         <div className="tab-pane fade show active"  role="tabpanel" >
         <div className="PackagingServicesContent">
               <h2>Blister packaging</h2>
               <h4>We provide blister packaging solutions for a wide range of products, ensuring convenience and protection.
               </h4>
               <p className="imagePosition">
                  <img src={blisterPacking} className="img-fluid100" />
               </p>
               <Link to={'/packagingservices/#blister-packing'} className="getStartedBtn m-0">
                    Read more
                  </Link>           
                </div>
              </div>
          </div>
  
      ),
      "PowderFilling": (
        <div className="tab-content">
         <div className="tab-pane fade show active"  role="tabpanel" >
         <div className="PackagingServicesContent">
               <h2>Powder Filling </h2>
               <h4>Our powder filling services accommodate various film specifications, offering cost-effective and product-compatible packaging solutions.</h4>
               <p className="imagePosition">
                  <img src={powderFilling} className="img-fluid100" />
               </p>
               <Link to={'/packagingservices/#powder-filling'} className="getStartedBtn m-0">
                    Read more
                  </Link>
                  </div>
               </div>

              </div>

      ),
      "BottlePacking": (
        <div className="tab-content">
         <div className="tab-pane fade show active"  role="tabpanel" >
         <div className="PackagingServicesContent">
               <h2>Bottle Packing</h2>
               <h4>From induction sealing and neck banding to full-body sleeves and custom labeling, we offer a complete bottle packaging solution.</h4>
               <p className="imagePosition">
                  <img src={bottlePacking} className="img-fluid100" />
               </p>
               <Link to={'/packagingservices/#bottling'} className="getStartedBtn m-0">
                    Read more
                  </Link>
                  </div>
              </div>
          </div>

      ),
    
    };

    return tabContents[activeTab];
  };

  return (
        <div className="PackagingServicesRow">
           <div className="left">
             <div 
                    className={`leftContent  ${activeTab ? 'show active' : ''}`} 
                    id={activeTab} 
    
                  >
                    {renderTabContent()}
                  </div>
                  </div>
            <div className="right">
                <h3>Packaging Services</h3>
                <ul 
                    className="nav nav-tabs MSTabs mobile-tabs" 
                    role="tablist" 
                    id="PackagingTab"
                    onClick={toggleMenu}
                  >
                    {tabs.map((tab) => (
                      <li 
                        key={tab.id} 
                        className={`nav-item ${isMenuCollapsed && activeTab !== tab.id ? 'd-none d-md-block' : ''} ${activeTab === tab.id ? 'active' : ''}`} 
                        role="tab"
                      >
                        <a 
                          className={`nav-link ${activeTab === tab.id ? 'active' : ''}`}
                          role="tab" 
                          data-bs-toggle="tab"
                          href={`#${tab.id}`}
                          aria-controls={tab.id}
                          onClick={() => handleTabChange(tab.id)}
                        ><i className="icon-arrow_tail_left"> </i>
                          {tab.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                  </div>
                  </div>
  );
};

export default PackaingMain;