import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../css/style.css";
import capsuleImg from "../assets/images/Capsules.jpg";
import tabletImg from "../assets/images/Tableting.jpg";
import tabletCoating from "../assets/images/Tablet_Coating.jpg";
import protien from "../assets/images/Protin.png";
import blisterPacking from "../assets/images/blisterpacking_02.jpg";
import contractManufacturing from "../assets/images/ContractManufacturing.png";
import powderFilling from "../assets/images/powderfilling.jpg";
import bottlePacking from "../assets/images/bottling.jpg";

const ManufacturingMain = () => {
  const [activeTab, setActiveTab] = useState("Capsules");
  const [isMenuCollapsed, setMenuCollapsed] = useState(true);

  const tabs = [
    { id: "Capsules", title: "Capsules" },
    { id: "Tablets", title: "Tablets" },
    { id: "TabletCoating", title: "Tablet coating" },
    { id: "PouchesSachetStickPack", title: "Pouches / sachet / stick pack" },
    { id: "Blending", title: "Blending" },
    { id: "ContractManufacturing", title: "Contract Manufacturing" },
  ];

  const handleTabChange = (tabId, event) => {
    event.preventDefault();
    setActiveTab(tabId);
    setMenuCollapsed(true);
  };

  const toggleMenu = () => {
    setMenuCollapsed(!isMenuCollapsed);
  };

  const renderTabContent = () => {
    const tabContents = {
      Capsules: (
        <div className="tab-content" id="manufacturingTabContent">
          <div className="tab-pane fade show active" role="tabpanel">
            <div className="manufacturingContent">
              <h2>Capsules</h2>

              <p>
                Designed for easy swallowing, our capsules dissolve quickly for
                improved absorption.
              </p>
              <p>
                <img src={capsuleImg} className="img-fluid100" />
              </p>
              <Link
                to={"/manufacturingservices/#capsules"}
                className="getStartedBtn m-0"
              >
                Read more
              </Link>
            </div>
          </div>
        </div>
      ),
      Tablets: (
        <div className="tab-content" id="manufacturingTabContent">
          <div className="tab-pane fade show active" role="tabpanel">
            <div className="manufacturingContent">
              <h2>Tableting</h2>

              <p>
                Traditional tablets remain one of the most cost-effective
                supplement forms.
              </p>
              <p>
                <img src={tabletImg} className="img-fluid100" />
              </p>
              <Link
                to={"/manufacturingservices/#tablets"}
                className="getStartedBtn m-0"
              >
                Read more
              </Link>
            </div>
          </div>
        </div>
      ),
      TabletCoating: (
        <div className="tab-content" id="manufacturingTabContent">
          <div className="tab-pane fade show active" role="tabpanel">
            <div className="manufacturingContent">
              <h2>Tablet Coating </h2>

              <p>
                With expertise in continuous tablet coating, we utilize advanced
                technology to enhance product stability and effectiveness.
              </p>
              <p>
                <img src={tabletCoating} className="img-fluid100" />
              </p>
              <Link
                to={"/manufacturingservices/#tablet-coating"}
                className="getStartedBtn m-0"
              >
                Read more
              </Link>
            </div>
          </div>
        </div>
      ),

      PouchesSachetStickPack: (
        <div className="tab-content" id="manufacturingTabContent">
          <div className="tab-pane fade show active" role="tabpanel">
            <div className="manufacturingContent">
              <h2>Pouches, Sachets & Stick Packs</h2>
              <h4>
                We offer flexible packaging solutions tailored to various
                supplement formats.
              </h4>
              <p>
                <img src={protien} className="img-fluid100" />
              </p>
              <Link
                to={"/manufacturingservices/#blending"}
                className="getStartedBtn m-0"
              >
                Read more
              </Link>
            </div>
          </div>
        </div>
      ),
      Blending: (
        <div className="tab-content" id="manufacturingTabContent">
          <div className="tab-pane fade show active" role="tabpanel">
            <div className="manufacturingContent">
              <h2>Blending</h2>
              <h4>
                We specialize in protein powders, meal replacement drinks, and
                custom blends to meet your exact specifications.
              </h4>
              <p>
                <img src={protien} className="img-fluid100" />
              </p>
              <Link
                to={"/manufacturingservices/#blending"}
                className="getStartedBtn m-0"
              >
                Read more
              </Link>
            </div>
          </div>
        </div>
      ),

      ContractManufacturing: (
        <div className="tab-content" id="manufacturingTabContent">
          <div className="tab-pane fade show active" role="tabpanel">
            <div className="manufacturingContent">
              <h2>Contract Manufacturing</h2>
              <p>
                Leverage our experience and cutting-edge technology to
                manufacture innovative and high-quality supplements.
              </p>
              <p>
                <img src={contractManufacturing} className="img-fluid100" />
              </p>
              <Link
                to={"/manufacturingservices/#contract-manufacturing"}
                className="getStartedBtn m-0"
              >
                Read more
              </Link>
            </div>
          </div>
        </div>
      ),
    };

    return tabContents[activeTab];
  };

  return (
    <div className="manufacturingRow">
      <div className="left">
        <h3>Manufacturing Services</h3>
        <ul
          className="nav nav-tabs MSTabs mobile-tabs container"
          role="tablist"
          id="manufacturingTab1"
          onClick={toggleMenu}
        >
          {tabs.map((tab) => (
            <li
              key={tab.id}
              className={`nav-item ${
                isMenuCollapsed && activeTab !== tab.id
                  ? "d-none d-md-block"
                  : ""
              } ${activeTab === tab.id ? "active" : ""}`}
              role="tab"
            >
              <a
                className={`nav-link ${activeTab === tab.id ? "active" : ""}`}
                role="tab"
                data-bs-toggle="tab"
                href={`#${tab.id}`}
                aria-controls={tab.id}
                onClick={(e) => handleTabChange(tab.id, e)}
              >
                {tab.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="right">
        <div
          className={`rightContent ${activeTab ? "active show" : ""}`}
          id={activeTab}
        >
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

export default ManufacturingMain;
