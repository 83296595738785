import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./pages/MainPage.js";
import ManufacturingServices from "./pages/ManufacturingServices.js";
import PackagingServices from "./pages/PackagingServices.js";
import Overview from "./pages/Overview.js";
import ContactUs from "./pages/Contactus.js";
import Career from "./pages/Career.js";
import ProductsList from "./pages/ProductsList.js";
import Navbar from "./components/Navbar.js";
import Footer from "./components/Footer.js";
import SingleProduct from "./pages/SingleProduct.js";
import "./css/fonts/IconFonts/css/pharmalinefonts.css";
import CategoryPage from "./pages/ProductCategory.js";
import GetQuote from "./pages/GetQuote.js";
import Login from "./pages/Login.js";
import PrivateRoute from "./components/PrivateRoute.js";
import Dashboard from "./pages/Dashboard.js";
import ScrollToTop from "./components/ScrollToTop.js";
import { Broucher } from "./pages/Broucher.js";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route index element={<Main />} />
          <Route path="/home" element={<Main />} />
          <Route
            path="/manufacturingservices"
            element={<ManufacturingServices />}
          />
          <Route
            path="/manufacturingservices/#capsules"
            element={<ManufacturingServices />}
          />
          <Route
            path="/manufacturingservices/#tablet-coating"
            element={<ManufacturingServices />}
          />
          <Route
            path="/manufacturingservices/#tablets"
            element={<ManufacturingServices />}
          />
          <Route
            path="/manufacturingservices/#blending"
            element={<ManufacturingServices />}
          />
          <Route
            path="/manufacturingservices/#contract-manufacturing"
            element={<ManufacturingServices />}
          />

          <Route
            path="/manufacturingservices/capsules"
            element={<ManufacturingServices />}
          />
          <Route
            path="/manufacturingservices/tablet-coating"
            element={<ManufacturingServices />}
          />
          <Route
            path="/manufacturingservices/tablets"
            element={<ManufacturingServices />}
          />
          <Route
            path="/manufacturingservices/blending"
            element={<ManufacturingServices />}
          />
          <Route
            path="/manufacturingservices/contract-manufacturing"
            element={<ManufacturingServices />}
          />

          <Route path="/packagingservices" element={<PackagingServices />} />
          <Route
            path="/packagingservices/blister-packing"
            element={<PackagingServices />}
          />
          <Route
            path="/packagingservices/#powder-filling"
            element={<PackagingServices />}
          />
          <Route path="/packagingservices/#bottling" element={<PackagingServices />} />
          
          <Route
            path="/packagingservices/blister-packing"
            element={<PackagingServices />}
          />
          <Route
            path="/packagingservices/powder-filling"
            element={<PackagingServices />}
          />
          <Route path="/packagingservices/bottling" element={<PackagingServices />} />

          <Route path="/Overview" element={<Overview />} />
          <Route path="/getquote" element={<GetQuote />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/Career" element={<Career />} />
          <Route path="/productsList" element={<ProductsList />} />
          <Route path="/product/:id" element={<SingleProduct />} />
          <Route path="/productslist/:category" element={<CategoryPage />} />
          <Route path="/Login" element={<Login />} />
          <Route
            path="/Dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route path="/get-quote" element={<GetQuote />}></Route>
          <Route path="/broucher" element={<Broucher />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
