export const Broucher = () => {
    return (
    <div className="broucher-div">
        <iframe
        title="Broucher Viewer"
        style={{
          width: "100%",
          height: "100%",
        }}
        src="https://online.pubhtml5.com/ynyys/wpbd/"
        seamless
        scrolling="no"
        frameBorder="0"
        allowTransparency
        allowFullScreen
      ></iframe>
    </div>  
      
    );
  };
  