import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ironSvg from "../assets/images/svg/Iron.svg";
import sportsSvg from "../assets/images/svg/Sports.svg";
import bloodSugarSvg from "../assets/images/svg/Blood_Sugar.svg";
import performanceSvg from "../assets/images/svg/Performance.svg";
import probioticsSvg from "../assets/images/svg/Probiotic.svg";
import healthWealthSvg from "../assets/images/svg/HealthWellbeaing.svg";
import weightManagementSvg from "../assets/images/svg/Weightmanagement.svg";
import axios from "axios";

const ProductsList = () => {
  const [productCounts, setProductCounts] = useState({});

  const products = [
    {
      title: "Antioxidant",
      icon: "icon-Antioxidant",
      color: "orangeColor",
      Link: "/productslist/Antioxidant",
      category: "Antioxidant",
    },
    {
      title: "Calcium",
      icon: "icon-Calcium",
      color: "Pink",
      Link: "/productslist/Calcium",
      category: "Calcium",
    },
    {
      title: "Energy Support",
      icon: "icon-Energy",
      color: "DarkRed",
      Link: "/productslist/EnergySupport",
      category: "EnergySupport",
    },
    {
      title: "Blood sugar support",
      icon: "",
      color: "DarkBrown",
      Link: "/productslist/BloodSugarSupport",
      svg: bloodSugarSvg,
      category: "BloodSugarSupport",
    },
    {
      title: "Cognitive Function",
      icon: "icon-Cognitive",
      color: "Pink",
      Link: "/productslist/CognitiveFunction",
      category: "CognitiveFunction",
    },
    {
      title: "Gummies",
      icon: "icon-Gummies",
      color: "Purple",
      Link: "/productslist/Gummies",
      category: "Gummies",
    },
    {
      title: "Bone & Joint Health",
      icon: "icon-bonehealth",
      color: "DarkGreen",
      Link: "/productslist/Bone&JointHealth",
      category: "Bone&JointHealth",
    },
    {
      title: "Digestive Support",
      icon: "icon-digestive",
      color: "LightPink",
      Link: "/productslist/DigestiveSupport",
      category: "DigestiveSupport",
    },
    {
      title: "Hair, Skin & Nails",
      icon: "icon-Hair_Skin_Nails",
      color: "DarkPink",
      Link: "/productslist/HairSkin&Nails",
      category: "HairSkin&Nails",
    },
    {
      title: "Vitamin D",
      icon: "icon-Vitamin_D",
      color: "LightGreen",
      Link: "/productslist/VitaminD",
      category: "VitaminD",
    },
    {
      title: "Electrolyte Supplements",
      icon: "icon-Electrolyte",
      color: "LightBlue",
      Link: "/productslist/ElectrolyteSupplements",
      category: "ElectrolyteSupplements",
    },
    {
      title: "Heart Health",
      icon: "icon-heart",
      color: "DarkBlue",
      Link: "/productslist/HeartHealth",
      category: "HeartHealth",
    },
    {
      title: "Immune Support",
      icon: "icon-immune",
      color: "DarkBrown",
      Link: "/productslist/ImmuneSupport",
      category: "ImmuneSupport",
    },
    {
      title: "Men's Health",
      icon: "icon-menshealth",
      color: "LightPink2",
      Link: "/productslist/MensHealth",
      category: "MensHealth",
    },
    {
      title: "Sleep Support",
      icon: "icon-sleepsupport",
      color: "LightGreen2",
      Link: "/productslist/SleepSupport",
      category: "SleepSupport",
    },
    {
      title: "Iron Supplements",
      icon: "",
      color: "Meroon",
      Link: "/productslist/IronSupplements",
      svg: ironSvg,
      category: "IronSupplements",
    },
    {
      title: "Mood Support",
      icon: "icon-moodsupport",
      color: "LightBlue2",
      Link: "/productslist/MoodSupport",
      category: "MoodSupport",
    },
    {
      title: "Sport Nutrition",
      icon: "",
      color: "yellow",
      Link: "/productslist/SportNutrition",
      svg: sportsSvg,
      category: "SportNutrition",
    },
    {
      title: "Kids Health",
      icon: "icon-kids",
      color: "Blue",
      Link: "/productslist/KidsHealth",
      category: "KidsHealth",
    },
    {
      title: "Performance",
      icon: "",
      color: "DarkYellow",
      Link: "/productslist/Performance",
      svg: performanceSvg,
      category: "Performance",
    },
    {
      title: "Vision Health",
      icon: "icon-vision",
      color: "DarkMaroon",
      Link: "/productslist/VisionHealth",
      category: "VisionHealth",
    },
    {
      title: "Liver Support",
      icon: "icon-liver_support",
      color: "Blue",
      Link: "/productslist/LiverSupport",
      category: "LiverSupport",
    },
    {
      title: "Probiotics",
      icon: "",
      color: "LightGreen2",
      Link: "/productslist/Probiotics",
      svg: probioticsSvg,
      category: "Probiotics",
    },
    {
      title: "Health & Well being",
      icon: "",
      color: "Blue2",
      Link: "/productslist/Health&WellBeing",
      svg: healthWealthSvg,
      category: "Health&WellBeing",
    },
    {
      title: "Weight Management",
      icon: "",
      color: "Violet",
      Link: "/productslist/WeightManagement",
      svg: weightManagementSvg,
      category: "WeightManagement",
    },
    {
      title: "Women's health",
      icon: "icon-womens_health",
      color: "LightPink",
      Link: "/productslist/Women’sHealth",
      category: "Women’sHealth",
    },
    {
      title: "Cold and Flu",
      icon: "icon-cold",
      color: "LightBrown2",
      Link: "/productslist/ColdAndFlu",
      category: "ColdAndFlu",
    },
    {
      title: "Thyroid Aid",
      icon: "icon-throat",
      color: "Blue2",
      Link: "/productslist/ThyroidAid",
      category: "ThyroidAid",
    },
    {
      title: "Inflamation Aid",
      icon: "icon-heart",
      color: "LightViolet",
      Link: "/productslist/InflamationAid",
      category: "InflamationAid",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    let isMounted = true;

    const fetchProductsAndCounts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/products/`);
        if (!isMounted) return;

        const productsData = response.data;
        const countsMap = {};

        // Calculate counts for each therapeuticCategory
        productsData.forEach((product) => {
          const category = product.therapeuticCategory;
          if (category) {
            countsMap[category] = (countsMap[category] || 0) + 1;
          }
        });

        setProductCounts(countsMap);
      } catch (error) {
        console.error("Error fetching products:", error.response?.data || error.message);
      }
    };

    fetchProductsAndCounts();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <div className="breadcrumbsBlue">
        <div className="container">
          <Link to={"/home"}> Home </Link> /{" "}
          <Link to={"/productlist"}> Products </Link>
        </div>
      </div>
      <div className="InnerPageBody">
        <div className="container">
          <div className="ProductTopContent">
            <h2>Product categories</h2>
            <div className="ProcutCount">
              <div className="ListOfProducts">
                {Object.values(productCounts).reduce((a, b) => a + b, 0)}{" "}
                Products
              </div>
              <div className="ListOfProducts border-0">
                {Object.keys(productCounts).length} categories
              </div>
            </div>
            <p>
              Our diverse portfolio of world-renowned brands has defined the
              iconic Pharmaline identity, and through our innovative products*,
              we continually strive to improve people's lives.
            </p>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="vehicle-detail-banner banner-content clearfix">
                <div className="product-slider">
                  <div className="content">
                    <div className="ProductSlider">
                      {products.map((product, index) => (
                        <a
                          key={index}
                          href={product.Link}
                          className={`productItem ${product.color} flex items-center p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow`}
                          title={`${product.title} (${
                            productCounts[product.title] || 0
                          })`}
                        >
                          <span className="ProductIcon">
                            {product.svg ? (
                              <i>
                                <img
                                  src={product.svg}
                                  className="ProductSVG w-8 h-8"
                                  alt={product.title}
                                />
                              </i>
                            ) : (
                              <i className={product.icon}></i>
                            )}
                          </span>
                          <h4
                            className="ml-4 text-lg"
                            style={{ fontSize: "18px" }}
                          >
                            {product.title}{" "}
                            <span
                              className="text-sm"
                              style={{ fontSize: "18px" }}
                            >
                              ({productCounts[product.category] || 0})
                            </span>
                          </h4>
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsList;
