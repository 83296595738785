import React, { useEffect, useState } from "react";
// import Slider from "../components/Slider.js";
import ManufacturingMain from "../components/ManufacturingMain.js";
import PackaingMain from "../components/PackaingMain.js";
import { Link } from "react-router-dom";
import iron from "../assets/images/svg/Iron.svg";
import sports from "../assets/images/svg/Sports.svg";
import cvcv from "../assets/images/CVCV.png";
import qai from "../assets/images/QAI.png";
import nfscs from "../assets/images/NSFCS.png";
import koshar from "../assets/images/Koshar.png";
import fda from "../assets/images/FDA.png";
import gmp from "../assets/images/GMP_RDS.png";
import healthCanada from "../assets/images/Health_Canada.png";
import halal from "../assets/images/Halal.png";
import SliderCarousel from "../components/SliderCarousel.js";

const MainPage = () => {
  const [activeOverviewTab, setActiveOverviewTab] = useState("CompanyOverview");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOverviewTabClick = (tabId, e) => {
    e.preventDefault();
    setActiveOverviewTab(tabId);
  };

  return (
    <div>
      {/* <Slider /> */}
      <div style={{ marginTop: "100px" }}>
      <SliderCarousel/>
      </div>
      <div className="companyOverview">
        <div className="crossRight"></div>
        <div className="left">
          <div className="leftbg">
            <ul
              className="nav nav-tabs newTabs"
              id="overviewTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <a
                  className={`nav-link ${
                    activeOverviewTab === "CompanyOverview" ? "active" : ""
                  }`}
                  id="CompanyOverview-tab"
                  onClick={(e) => handleOverviewTabClick("CompanyOverview", e)}
                  href="#CompanyOverview"
                  role="tab"
                  aria-controls="CompanyOverview"
                  aria-selected={activeOverviewTab === "CompanyOverview"}
                >
                  Company overview
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className={`nav-link ${
                    activeOverviewTab === "Vision" ? "active" : ""
                  }`}
                  id="Vision-tab"
                  onClick={(e) => handleOverviewTabClick("Vision", e)}
                  href="#Vision"
                  role="tab"
                  aria-controls="Vision"
                  aria-selected={activeOverviewTab === "Vision"}
                >
                  Our Vision
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="right">
          <div className="rightContent">
            <div className="tab-content" id="myTabContent">
              <div
                className={`tab-pane fade ${
                  activeOverviewTab === "CompanyOverview" ? "show active" : ""
                }`}
                id="CompanyOverview"
                role="tabpanel"
              >
                <div className="previewContent">
                  <h2>
                    <span className="leftquote">
                      <i className="icon-quote-left"></i>
                    </span>
                    We are a reliable and competent partner you can trust.
                    <span className="rightquote">
                      <i className="icon-quote-right"></i>
                    </span>
                  </h2>
                  <p>
                    Our state-of-the-art manufacturing facility allows us to be
                    flexible with our production. Our strength is to supply low
                    minimum order quantities (MOQ's).
                  </p>
                  <Link to={"/overview"} className="redBtn">
                    About Us
                  </Link>
                </div>
              </div>
              <div
                className={`tab-pane fade ${
                  activeOverviewTab === "Vision" ? "show active" : ""
                }`}
                id="Vision"
                role="tabpanel"
              >
                <div className="previewContent">
                  <h2 className="text-center mx-0">Vision</h2>
                  <p>
                    At Pharmaline Inc., we are dedicated to exceeding customer
                    expectations by providing focused and agile solutions. Our
                    goal is to design, create, and deliver customized products
                    in close collaboration with our partners.
                  </p>
                  <Link to={"/overview"} className="redBtn">
                    About Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ManufacturingMain />
      <PackaingMain />

      <div class="Bottom-shadow">
        <div class="ourProducts">
          <div class="left">
            <div class="leftbg">
              <div class="leftContent">
                We manufacture high-quality supplements that promote health and
                well-being, including:
              </div>
            </div>
          </div>
          <div class="right">
            <ul class="productList">
              <li>
                {" "}
                <a href="/productslist/HeartHealth">
                  <span class="icon">
                    {" "}
                    <i class="icon-heart"></i>{" "}
                  </span>
                  <p>Heart health</p>{" "}
                </a>
              </li>
              <li>
                {" "}
                <a href="/productslist/SleepSupport">
                  <span class="icon">
                    {" "}
                    <i class="icon-sleep"></i>{" "}
                  </span>
                  <p>Sleep Supprt</p>{" "}
                </a>
              </li>
              <li>
                {" "}
                <a href="/productslist/IronSupplements">
                  <span class="icon">
                    {" "}
                    <i class="">
                      {" "}
                      <img src={iron} class="ProductSVG" />
                    </i>{" "}
                  </span>
                  <p>Iron Supplements</p>{" "}
                </a>
              </li>
              <li>
                {" "}
                <a href="/productslist/ImmuneSupport">
                  <span class="icon">
                    {" "}
                    <i class="icon-immune"></i>{" "}
                  </span>
                  <p>Immune support</p>{" "}
                </a>
              </li>
              <li>
                {" "}
                <a href="/productslist/Bone&JointHealth">
                  <span class="icon">
                    {" "}
                    <i class="icon-bonehealth"></i>{" "}
                  </span>
                  <p>Bone & Joint Health</p>{" "}
                </a>
              </li>
              <li>
                {" "}
                <a href="/productslist/DigestiveSupport">
                  <span class="icon">
                    {" "}
                    <i class="icon-digestive"></i>{" "}
                  </span>
                  <p>Digestive Support</p>{" "}
                </a>
              </li>
              <li>
                {" "}
                <a href="/productslist/MoodSupport">
                  <span class="icon">
                    {" "}
                    <i class="icon-mood"></i>{" "}
                  </span>
                  <p>Mood Support</p>{" "}
                </a>
              </li>
              <li>
                {" "}
                <a href="/productslist/SportNutrition">
                  <span class="icon">
                    {" "}
                    <i>
                      {" "}
                      <img src={sports} class="ProductSVG" />
                    </i>{" "}
                  </span>
                  <p>Sports</p>{" "}
                </a>
              </li>
            </ul>
            <div class="text-center">
              <a href="productsList" class="redBtn col-12 col-lg-3">
                {" "}
                View all{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="qualityAssurance">
        <div className="container">
          <div className="qualityAssuranceImag">
            <div className="data">
              <h3>Quality Assurance</h3>
              <p>
                We adhere to GMP (Good Manufacturing Practices), ensuring our
                products meet the highest standards of identity, strength,
                composition, quality, and purity as stated on the label.
              </p>
              <p>
              At Pharmaline Inc., we are committed to delivering excellence.
              </p>
              <h5>We received and stand with</h5>
              <ul className="gmpList" style={{ marginBottom: "40px" }}>
                <li>
                  {" "}
                  <img src={cvcv} alt="Veg Cert" className="QAimage" />
                </li>
                <li>
                  {" "}
                  <img
                    src={qai}
                    alt="Quality Assurance International"
                    className="QAimage"
                  />
                </li>
                <li>
                  {" "}
                  <img
                    src={nfscs}
                    alt="NSF Certified Sport"
                    className="QAimage"
                  />
                </li>
                <li>
                  {" "}
                  <img src={koshar} alt="Koshar" className="QAimage" />
                </li>
              </ul>
              <ul className="gmpList">
                <li>
                  {" "}
                  <img src={fda} alt="FDA" className="QAimage" />
                </li>
                <li>
                  {" "}
                  <img
                    src={gmp}
                    alt="GMP Registerd Dietery supplement"
                    className="QAimage"
                  />
                </li>
                <li>
                  {" "}
                  <img
                    src={healthCanada}
                    alt="Health Canada"
                    className="QAimage"
                  />
                </li>
                <li>
                  {" "}
                  <img src={halal} alt="Halal" className="QAimage" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="workTogether">
        <div className="left"></div>
        <div className="data">
          <h3>Let’s work together</h3>
          <p style={{color: "#fff"}}>Partner with us to create high-quality, customized health solutions that drive success in your market.</p>
          <div className="btnRow">
            <a href="/get-quote" className="getQuote">
              {" "}
              Get quote
            </a>
            <a href="/contact" className="getQuote d-none">
              {" "}
              Contact us
            </a>
          </div>
        </div>
        <div className="right"></div>
      </div>
    </div>
  );
};

export default MainPage;
