import React, { useEffect, useRef, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import "../css/menu.css";
import "../css/style.css";

const CategoryPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const { category } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const categories = [
    { name: "Antioxidant", link: "/productslist/Antioxidant" },
    { name: "Blood Sugar Support", link: "/productslist/BloodSugarSupport" },
    { name: "Bone & Joint Health", link: "/productslist/Bone&JointHealth" },
    { name: "Vitamin D", link: "/productslist/VitaminD" },
    { name: "Calcium", link: "/productslist/Calcium" },
    { name: "Cognitive Function", link: "/productslist/CognitiveFunction" },
    { name: "Digestive Support", link: "/productslist/DigestiveSupport" },
    { name: "Electrolyte Supplements", link: "/productslist/ElectrolyteSupplements" },
    { name: "Energy Support", link: "/productslist/EnergySupport" },
    { name: "Gummies", link: "/productslist/Gummies" },
    { name: "Hair, Skin & Nails", link: "/productslist/HairSkin&Nails" },
    { name: "Heart Health", link: "/productslist/HeartHealth" },
    { name: "Immune Support", link: "/productslist/ImmuneSupport" },
    { name: "Iron supplements", link: "/productslist/IronSupplements" },
    { name: "Kids Health", link: "/productslist/KidsHealth" },
    { name: "Liver Support", link: "/productslist/LiverSupport" },
    { name: "Men's Health", link: "/productslist/MensHealth" },
    { name: "Mood Support", link: "/productslist/MoodSupport" },
    { name: "Performance", link: "/productslist/Performance" },
    { name: "Probiotics", link: "/productslist/Probiotics" },
    { name: "Sleep Support", link: "/productslist/SleepSupport" },
    { name: "Sport Nutrition", link: "/productslist/SportNutrition" },
    { name: "Vision Health", link: "/productslist/VisionHealth" },
    { name: "Health & Well being", link: "/productslist/Health&WellBeing" },
    { name: "Weight Management", link: "/productslist/WeightManagement" },
    { name: "Women's Health", link: "/productslist/Women’sHealth" },
    { name: "Cold and Flu", link: "/productslist/ColdAndFlu" },
    { name: "Thyroid Aid", link: "/productslist/ThyroidAid" },
    { name: "Inflamation Aid", link: "/productslist/InflamationAid" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on load
  }, []);
  
  useEffect(() => {
    const fetchProducts = async () => {
      if (!category) return;
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/products/category/${category}`
        );
        setProducts(response.data);
      } catch (err) {
        setError("Failed to fetch products");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [category]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className="breadcrumbsBlue">
        <div className="container">
          <Link to={"/home"}> Home </Link> /{" "}
          <Link to={`/productslist`}> Products </Link> / {decodeURIComponent(category.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([a-zA-Z])&([a-zA-Z])/g, '$1 & $2').replace(/%20/g, ' '))}
        </div>
      </div>

      <div className="InnerPageBody">
        <br></br>
        <br></br>
        <br></br>
        <div className="container">
          <div className="topHeader">
            {/* Left Section */}
            <div className="left">
              <h1>{decodeURIComponent(category.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([a-zA-Z])&([a-zA-Z])/g, '$1 & $2').replace(/%20/g, ' '))}</h1>
              <span className="productNum">{products.length} products</span>
            </div>

            <div className="right" ref={menuRef}>
              <div className={`select-menu ${isMenuOpen ? "active" : ""}`}>
                <div className="select-btn" onClick={toggleMenu}>
                  <span className="sBtn-text">{decodeURIComponent(category.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([a-zA-Z])&([a-zA-Z])/g, '$1 & $2').replace(/%20/g, ' '))}</span>
                  <i className="icon-downarrow"></i>
                </div>

                {isMenuOpen && (
                  <ul className="options">
                    {categories.map((category) => (
                      <li key={category.name} className="option">
                        <a
                          href={category.link}
                          className="option-text"
                          onClick={toggleMenu}
                        >
                          {category.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="product-category-page">
            <ul className="ProductList">
              {products.length > 0 ? (
                products.map((product) => (
                  <li key={product.id} className="ProductItems">
                    <div className="item">
                      <span className="pic">
                        <img
                          src={product.imagePath}
                          alt={product.name}
                          className="w-full h-40 object-cover"
                        />
                        <div className="ProductOverlay">
                          <div className="text">
                            {product.description.length > 150
                              ? `${product.description.slice(0, 150)}...`
                              : product.description}
                          </div>
                        </div>
                      </span>
                      <div className="ItemContent">
                        <h6>{product.category}</h6>
                        <h5>{product.name}</h5>
                        <a
                          href={`/product/${product.id}`}
                          className="detailsBtn"
                        >
                          Details
                        </a>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <p>No products found in this category.</p>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryPage;
