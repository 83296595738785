import React, { useState, useEffect } from "react";
import axios from "axios";

const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page on load
    }, []);

    // State to store form data
    const [formData, setFormData] = useState({
        enduserFirstName: "",
        enduserLastName: "",
        enduserEmail: "",
        enduserMobile: "",
        enduserMsg: "",
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false);

    // Handle form input changes
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setMessage("");
        setSuccess(false);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/contact-us`, formData);
            setMessage("Thank you for reaching out! Our support team will contact you within 24 hours.");
            setSuccess(true);
            setFormData({ 
                enduserFirstName: "", 
                enduserLastName: "", 
                enduserEmail: "", 
                enduserMobile: "", 
                enduserMsg: "" 
            });
        } catch (error) {
            setMessage("Failed to send message. Please try again later.");
            setSuccess(false);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className="InnerPageBanner">
                <div className="contactBanner">Get in touch with us!</div>
            </div>

            <div className="InnerPageBody p-0">
                <div className="contactContent">
                    <div className="left">
                        <div className="contactInfo">
                            <h3>Want to speak?</h3>
                            <h4>Contact us</h4>
                            <p>Office: +1-905 812 3916</p>
                            <p>FAX: +1-905-248-5404</p>
                            <p>Email: sales@pharmalineinc.com</p>
                        </div>
                        <div className="contactInfo">
                            <h4>Office hours</h4>
                            <p>Mon- Fri: 8 am to 5 pm (EST)</p>
                        </div>
                        <div className="contactInfo">
                            <h4>Address</h4>
                            <p>Unit 5 2835 Argentia Road, Mississauga, ON, Canada L5N 8G6</p>
                        </div>
                    </div>
                    <div className="right">
                        <div className="contactForm">
                            <h1>Can we help?</h1>
                            <h3>Fill out the form below or reach out to us directly</h3>

                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="enduserFirstName"
                                            placeholder="First name"
                                            value={formData.enduserFirstName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="enduserLastName"
                                            placeholder="Last name"
                                            value={formData.enduserLastName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <input
                                            className="form-control"
                                            type="email"
                                            name="enduserEmail"
                                            placeholder="Email"
                                            value={formData.enduserEmail}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="enduserMobile"
                                            placeholder="Phone"
                                            value={formData.enduserMobile}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-lg-12">
                                        <textarea
                                            className="form-control"
                                            name="enduserMsg"
                                            rows="5"
                                            placeholder="Leave a comment"
                                            value={formData.enduserMsg}
                                            onChange={handleChange}
                                            required
                                        ></textarea>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-lg-12">
                                        <button className="redBtn" type="submit" disabled={isSubmitting}>
                                            {isSubmitting ? "Submitting..." : "Submit"}
                                        </button>
                                    </div>
                                </div>
                            </form>

                            {message && (
                                <div className={`contactMessage ${success ? "success" : "error"}`}>
                                    <h3>{message}</h3>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactUs;