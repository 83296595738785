import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEdit, FaTrash, FaPlus, FaMinus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const token = localStorage.getItem("token");
  const [products, setProducts] = useState([]);
  const [formData, setFormData] = useState({
    id: null,
    name: "",
    code: "",
    compositions: [{ activeIngredient: "", quantity: "" }],
    description: "",
    dosageForm: "",
    therapeuticCategory: "",
    imagePath: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const therapeuticCategory = [
    { value: "Antioxidant", label: "Antioxidant" },
    { value: "Calcium", label: "Calcium" },
    { value: "EnergySupport", label: "Energy Support" },
    { value: "BloodSugarSupport", label: "Blood sugar support" },
    { value: "CognitiveFunction", label: "Cognitive Function" },
    { value: "Gummies", label: "Gummies" },
    { value: "Bone&JointHealth", label: "Bone & Joint Health" },
    { value: "DigestiveSupport", label: "Digestive Support" },
    { value: "HairSkin&Nails", label: "Hair, Skin & Nails" },
    { value: "VitaminD", label: "Vitamin D" },
    { value: "ElectrolyteSupplements", label: "Electrolyte Supplements" },
    { value: "HeartHealth", label: "Heart Health" },
    { value: "ImmuneSupport", label: "Immune Support" },
    { value: "MensHealth", label: "Men's Health" },
    { value: "SleepSupport", label: "Sleep Support" },
    { value: "IronSupplements", label: "Iron Supplements" },
    { value: "MoodSupport", label: "Mood Support" },
    { value: "SportNutrition", label: "Sport Nutrition" },
    { value: "KidsHealth", label: "Kids Health" },
    { value: "Performance", label: "Performance" },
    { value: "VisionHealth", label: "Vision Health" },
    { value: "LiverSupport", label: "Liver Support" },
    { value: "Probiotics", label: "Probiotics" },
    { value: "Health&WellBeing", label: "Health & Well being" },
    { value: "WeightManagement", label: "Weight Management" },
    { value: "Women’sHealth", label: "Women's health" },
    { value: "ColdAndFlu", label: "Cold and Flu" },
    { value: "ThyroidAid", label: "Thyroid Aid" },
    { value: "InflamationAid", label: "Inflamation Aid" },
  ];

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/products`
      );
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on load
  }, []);

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "imagePath" && files?.[0]) {
      setFormData((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleCompositionChange = (index, e) => {
    const { name, value } = e.target;
    const updatedCompositions = formData.compositions.map((comp, i) =>
      i === index ? { ...comp, [name]: value } : comp
    );
    setFormData((prev) => ({ ...prev, compositions: updatedCompositions }));
  };

  const addCompositionField = () => {
    setFormData((prev) => ({
      ...prev,
      compositions: [...prev.compositions, { activeIngredient: "", quantity: "" }],
    }));
  };

  const removeCompositionField = (index) => {
    const updatedCompositions = formData.compositions.filter(
      (_, i) => i !== index
    );
    setFormData((prev) => ({ ...prev, compositions: updatedCompositions }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Product Name is required";
    if (!formData.code.trim()) newErrors.code = "Product Code is required";
    if (!formData.description.trim())
      newErrors.description = "Description is required";
    if (!formData.dosageForm.trim())
      newErrors.dosageForm = "Dosage Form is required";
    if (!formData.therapeuticCategory.trim())
      newErrors.therapeuticCategory = "Category is required";

    formData.compositions.forEach((comp, index) => {
      if (!comp.activeIngredient.trim())
        newErrors[`activeIngredient-${index}`] = "Ingredient required";
      if (!comp.quantity.trim())
        newErrors[`quantity-${index}`] = "Quantity required";
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("code", formData.code);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("dosageForm", formData.dosageForm);
    formDataToSend.append("therapeuticCategory", formData.therapeuticCategory);
    formDataToSend.append(
      "activeIngredients",
      JSON.stringify(formData.compositions.map((comp) => comp.activeIngredient))
    );
    formDataToSend.append(
      "quantities",
      JSON.stringify(formData.compositions.map((comp) => comp.quantity))
    );

    if (formData.imagePath instanceof File) {
      // New image file was uploaded; it might be appended with a different key if needed.
      formDataToSend.append("imagePath", formData.imagePath);
    } else if (typeof formData.imagePath === "string" && formData.imagePath) {
      // No new file; send the existing image URL.
      formDataToSend.append("existingImagePath", formData.imagePath);
    }

    try {
      const url = formData.id
        ? `${process.env.REACT_APP_BASE_URL}/products/${formData.id}`
        : `${process.env.REACT_APP_BASE_URL}/products`;

      const method = formData.id ? "put" : "post";

      await axios[method](url, formDataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      setFormData({
        id: null,
        name: "",
        code: "",
        compositions: [{ activeIngredient: "", quantity: "" }],
        description: "",
        dosageForm: "",
        therapeuticCategory: "",
        imagePath: "",
      });
      setIsEditing(false);
      setShowModal(false);
      fetchProducts();
    } catch (error) {
      console.error("Error saving product:", error);
    }
  };

  const handleEdit = (product) => {
    setFormData({
      id: product.id,
      name: product.name,
      code: product.code,
      compositions:
        product.compositions?.length > 0
          ? product.compositions
          : [{ activeIngredient: "", quantity: "" }],
      description: product.description,
      dosageForm: product.dosageForm,
      therapeuticCategory: product.therapeuticCategory,
      imagePath: product.imagePath,
    });
    setIsEditing(true);
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        await axios.delete(`${process.env.REACT_APP_BASE_URL}/products/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        fetchProducts();
      } catch (error) {
        console.error("Error deleting product:", error);
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className="InnerPageBody p-0" style={{ marginTop: "100px" }}>
      {showModal ? (
        <div className="dashboard">
          <div className="right">
            <div className="contactForm">
              <div className="topHeader">
                <h3>{isEditing ? "Edit Product" : "Add Product"}</h3>
                <button className="redBtn" onClick={() => setShowModal(false)}>
                  Product List
                </button>
              </div>

              <form onSubmit={handleFormSubmit}>
                {/* Product Name and Code */}
                <div className="row mb-3">
                  <div className="col-12 col-lg-6">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Product Name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                    {errors.name && <p className="error">{errors.name}</p>}
                  </div>
                  <div className="col-12 col-lg-6">
                    <input
                      type="text"
                      name="code"
                      className="form-control"
                      placeholder="Product Code"
                      value={formData.code}
                      onChange={handleInputChange}
                    />
                    {errors.code && <p className="error">{errors.code}</p>}
                  </div>
                </div>

                {/* Compositions */}
                {formData.compositions.map((comp, index) => (
                  <div className="row mb-3" key={index}>
                    <div className="col-5">
                      <input
                        type="text"
                        name="activeIngredient"
                        className="form-control"
                        placeholder="Active Ingredient"
                        value={comp.activeIngredient}
                        onChange={(e) => handleCompositionChange(index, e)}
                      />
                      {errors[`activeIngredient-${index}`] && (
                        <p className="error">{errors[`activeIngredient-${index}`]}</p>
                      )}
                    </div>
                    <div className="col-5">
                      <input
                        type="text"
                        name="quantity"
                        className="form-control"
                        placeholder="Quantity"
                        value={comp.quantity}
                        onChange={(e) => handleCompositionChange(index, e)}
                      />
                      {errors[`quantity-${index}`] && (
                        <p className="error">{errors[`quantity-${index}`]}</p>
                      )}
                    </div>
                    <div className="col-2">
                      {index === 0 ? (
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={addCompositionField}
                        >
                          <FaPlus />
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => removeCompositionField(index)}
                        >
                          <FaMinus />
                        </button>
                      )}
                    </div>
                  </div>
                ))}

                {/* Other Fields */}
                <div className="row mb-3">
                  <div className="col-12 col-lg-6">
                    <input
                      type="text"
                      name="dosageForm"
                      className="form-control"
                      placeholder="Dosage Form"
                      value={formData.dosageForm}
                      onChange={handleInputChange}
                    />
                    {errors.dosageForm && (
                      <p className="error">{errors.dosageForm}</p>
                    )}
                  </div>
                  <div className="col-12 col-lg-6">
                    <select
                      name="therapeuticCategory"
                      className="form-control"
                      value={formData.therapeuticCategory}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Category</option>
                      {therapeuticCategory.map((cat) => (
                        <option key={cat.value} value={cat.value}>
                          {cat.label}
                        </option>
                      ))}
                    </select>
                    {errors.therapeuticCategory && (
                      <p className="error">{errors.therapeuticCategory}</p>
                    )}
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-12">
                    <input
                      type="file"
                      name="imagePath"
                      className="form-control"
                      onChange={handleInputChange}
                    />
                    {errors.imagePath && (
                      <p className="error">{errors.imagePath}</p>
                    )}
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-12">
                    <textarea
                      className="form-control"
                      name="description"
                      placeholder="Description"
                      rows="5"
                      value={formData.description}
                      onChange={handleInputChange}
                    ></textarea>
                    {errors.description && (
                      <p className="error">{errors.description}</p>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-4">
                    <button type="submit" className="redBtn">
                      {isEditing ? "Update Product" : "Add Product"}
                    </button>
                  </div>
                  <div className="col-4">
                    <button
                      type="button"
                      className="redBtn"
                      onClick={() => {
                        setShowModal(false);
                        setIsEditing(false);
                        setFormData({
                          id: null,
                          name: "",
                          code: "",
                          compositions: [{ activeIngredient: "", quantity: "" }],
                          description: "",
                          dosageForm: "",
                          therapeuticCategory: "",
                          imagePath: "",
                        });
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="dashboard">
          <div className="contactForm">
            <div className="topHeader">
              <h3>Product List</h3>
              <div>
                <button className="redBtn" onClick={() => setShowModal(true)}>
                  Add Product
                </button>
                <button className="redBtn ms-2" onClick={handleLogout}>
                  Logout
                </button>
              </div>
            </div>

            <table className="table table-striped table-bordered">
              <thead className="table-dark">
                <tr>
                  <th>Name</th>
                  <th>Code</th>
                  <th>Dosage Form</th>
                  <th>Category</th>
                  <th>Image</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => (
                  <tr key={product.id}>
                    <td>{product.name}</td>
                    <td>{product.code}</td>
                    <td>{product.dosageForm}</td>
                    <td>{product.therapeuticCategory}</td>
                    <td>
                      <img
                        src={product.imagePath}
                        alt={product.name}
                        className="img-thumbnail"
                        style={{ width: "100px" }}
                      />
                    </td>
                    <td>
                      <button
                        className="btn btn-warning btn-sm me-2"
                        onClick={() => handleEdit(product)}
                      >
                        <FaEdit />
                      </button>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleDelete(product.id)}
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
