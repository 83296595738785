import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import brand1 from "../assets/images/Brand_1.png";
import brand2 from "../assets/images/Brand_2.png";
import brand3 from "../assets/images/Brand_3.png";
import brand4 from "../assets/images/Brand_4.png";
import brand5 from "../assets/images/Brand_5.png";

const SingleProduct = () => {
  const [product, setProduct] = useState(null);
  const { id } = useParams(); // Get the product ID from the URL params

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on load
  }, []);
  
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/products/${id}`
        );
        if (!response.ok) {
          throw new Error("Product not found");
        }
        const data = await response.json();
        setProduct(data);
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    fetchProduct();
  }, [id]);

  if (!product) {
    return <div>Loading product details...</div>;
  }

  return (
    <>
      <div className="breadcrumbsBlue">
        <div className="container">
          <Link to="/home"> Home </Link> /{" "}
          <Link to={`/productslist/${product.therapeuticCategory}`}>
            {product.therapeuticCategory.replace(/([a-z])([A-Z])/g, '$1 $2')}
          </Link>{" "}
          / {product.name}
        </div>
      </div>
      <div className="InnerPageBody">
        <div className="container mTop100">
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-6">
              <div className="vehicle-detail-banner banner-content clearfix">
                <div className="product-slider">
                  <div className="rightsideImg">
                    <img src={product.imagePath} alt={product.name} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-6">
              <div className="productInfo">
                <h2>{product.name}</h2>
                <h3>NPN: {product.code}</h3>
                <table className="table Producttable">
                  <thead>
                    <tr>
                      <th>Product Composition</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Each Tablet contains</td>
                    </tr>
                    {product.compositions.map((composition, index) => (
                      <tr key={index}>
                        <td>
                          <span className="ProductDetailsText">
                            {composition.activeIngredient}
                          </span>
                          <span className="ProductDetailsText">
                            {composition.quantity}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p className="text">{product.description}</p>
                <p className="text2">
                  <b>Dosage Form:</b> {product.dosageForm}
                </p>
                <p className="text2">
                <b>Therapeutic Category:</b> {decodeURIComponent(product.therapeuticCategory.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/%20/g, ' '))}
                </p>

                <h4>We stand with</h4>
                  <ul class="gmpList my-3">
                    <li>
                      <img src={brand1} alt="Pharmaline Brand" />
                    </li>
                    <li>
                      <img src={brand2} alt="Pharmaline Brand" />
                    </li>
                    <li>
                      <img src={brand3} alt="Pharmaline Brand" />
                    </li>
                    <li>
                      <img src={brand4} alt="Pharmaline Brand" />
                    </li>
                    <li>
                      <img src={brand5} alt="Pharmaline Brand" />
                    </li>
                  </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleProduct;
