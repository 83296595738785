import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import jars2 from "../assets/images/Jars_02.png";
import blending2 from "../assets/images/Blending_2.png";
import blending3 from "../assets/images/Blending_3.png";
import capsules from "../assets/images/Capsules.png";
import vegCapsule from "../assets/images/vegetarianCapsules_Img.png";
import tablets from "../assets/images/Tabletes_Img.png";
import tabletsMobile from "../assets/images/Tabletes_Img_Mobile.png";
import tabletsBottom from "../assets/images/Tabletes_BottomImg.png";
import contract from "../assets/images/ContractManufacturing.png";
import tabletCoating from "../assets/images/Tablet_Coating.jpg";

const ManufacturingServices = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("Capsules");
  const [isMenuCollapsed, setMenuCollapsed] = useState(true);

  useEffect(() => {
      window.scrollTo(0, 0);
  }, []); 

  useEffect(() => {
    // Set initial tab based on URL path
    const path = location.pathname.split("/").pop();
    if (path === "capsules") {
      setActiveTab("Capsules");
    } else if (path === "tablets") {
      setActiveTab("Tablets");
    } else if (path === "tablet-coating") {
      setActiveTab("TabletCoating");
    } else if (path === "blending") {
      setActiveTab("Blending");
    } else if (path === "contract-manufacturing") {
      setActiveTab("ContractManufacturing");
    }
  }, [location]);

  useEffect(() => {
    const hash = location.hash;
    let tab_id = "";
  
    if (hash === "#capsules") {
      setActiveTab("Capsules");
      tab_id = "Capsules";
    } else if (hash === "#tablets") {
      setActiveTab("Tablets");
      tab_id = "Tablets";
    } else if (hash === "#tablet-coating") {
      setActiveTab("TabletCoating");
      tab_id = "TabletCoating";
    } else if (hash === "#blending") {
      setActiveTab("Blending");
      tab_id = "Blending";
    } else if (hash === "#contract-manufacturing") {
      setActiveTab("ContractManufacturing");
      tab_id = "ContractManufacturing";
    }
  
    if (tab_id) {
      const element = document.getElementById("tabs");
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 100);
      }
    }
  }, [location.hash]);  

  const tabs = [
    { id: "Capsules", title: "Capsules" },
    { id: "Tablets", title: "Tablets" },
    { id: "TabletCoating", title: "Tablet coating" },
    { id: "Blending", title: "Pouches / sachet / stick pack" },
    { id: "ContractManufacturing", title: "Contract Manufacturing" },
  ];

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
    switch (tabId) {
      case "Capsules":
        navigate("/manufacturingservices/#capsules");
        break;
      case "Tablets":
        navigate("/manufacturingservices/#tablets");
        break;
      case "TabletCoating":
        navigate("/manufacturingservices/#tablet-coating");
        break;
      case "Blending":
        navigate("/manufacturingservices/#blending");
        break;
      case "ContractManufacturing":
        navigate("/manufacturingservices/#contract-manufacturing");
        break;
      default:
        break;
    }
    setMenuCollapsed(true);
  };

  const toggleMenu = () => {
    setMenuCollapsed(!isMenuCollapsed);
  };

  const renderTabContent = () => {
    const tabContents = {
      Blending: (
        <div className="InnerPageTabsContent">
          <div className="row justify-content-center">
          <div className="row">
              <div className="col-l2 col-md-6 col-lg-4">
                <img src={jars2} alt="" className="blendingImageMain" />
                {/* <ul className="BlendingList">
                  <li>
                    <i className="icon-WheyProteins"></i> Whey Proteins
                  </li>
                  <li>
                    <i className="icon-sports"></i> Sports Nutrition
                  </li>
                  <li>
                    <i className="icon-overweight"></i> Weight loss aids
                  </li>
                </ul> */}
              </div>
              <div className="col-l2 col-md-6 col-lg-4">
                <img src={blending2} alt="" className="blendingImageMain" />
                {/* <ul className="BlendingList">
                  <li>
                    <i className="icon-Functionalfoods"></i> Functional foods
                  </li>
                  <li>
                    <i className="icon-Seeds"></i> Seeds & food Powders
                  </li>
                  <li>
                    <i className="icon-Herbalblends"></i> Herbal blends
                  </li>
                </ul> */}
              </div>
              <div className="col-l2 col-md-6 col-lg-4">
                <img src={blending3} alt="" className="blendingImageMain" />
                {/* <ul className="BlendingList">
                  <li>
                    <i className="icon-Greenpowders"></i> Green powders
                  </li>
                  <li>
                    <i className="icon-PlantProteins"></i> Plant Proteins
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="col-12 col-md-9">
              <h2>Pouches / sachet / stick pack</h2>
              <p>
                Pouches, sachets, and stick packs are a popular and convenient
                packaging solution for high-dosage products such as diet and
                fiber drinks, protein powders, and meal replacements. At
                Pharmaline, we specialize in creating custom blends and
                packaging them in a format that meets your needs—whether in
                bulk, jars, cans, buckets, or single-serve pouches.
              </p>
              <h3>Why Choose Pouches, Sachets & Stick Packs?</h3>
              <ul className="CapsulesList">
                <li>
                  Convenience: Perfect for on-the-go consumers, these formats
                  are easy to use and portable.
                </li>
                <li>
                  Precision Dosing: Ensures accurate and consistent dosing for
                  your customers.
                </li>
                <li>
                  Versatility: Ideal for a wide range of products, including
                  powders, granules, and liquids.
                </li>
                <li>
                  Customization: Tailored to your branding and product
                  requirements.
                </li>
              </ul>
              <h3>Our Expertise in Powder Formulas</h3>
              <p>
                Powder formulas offer greater flexibility in dosing and can be
                easily mixed into shakes, juices, or other liquids. Pharmaline
                is your trusted partner for creating nutritious, tasty, and
                functional powder solutions.
              </p>
              <p>We specialize in:</p>
              <ul className="CapsulesList">
                <li>
                  <strong>Whey Proteins:</strong> High-quality protein powders
                  for fitness and sports nutrition.
                </li>
                <li>
                  <strong>Sports Nutrition:</strong> Custom blends to support
                  athletic performance and recovery.
                </li>
                <li>
                  <strong>Weight Loss Aids:</strong> Formulations designed to
                  support healthy weight management.
                </li>
                <li>
                  <strong>Functional Foods:</strong> Nutrient-dense powders for
                  everyday wellness.
                </li>
                <li>
                  <strong>Seeds & Food Powders:</strong> Superfood blends for
                  added nutrition.
                </li>
                <li>
                  <strong>Herbal Blends:</strong> Natural formulations for
                  holistic health.
                </li>
                <li>
                  <strong>Green Powders:</strong> Plant-based nutrition for
                  detox and energy.
                </li>
                <li>
                  <strong>Plant Proteins:</strong> Vegan and vegetarian protein
                  options.
                </li>
              </ul>
              <h3>Packaging Solutions</h3>
              <p>
                We offer a variety of packaging options to suit your product and
                market needs:
              </p>
              <ul className="CapsulesList">
                <li>
                  <strong>Single-Serve Pouches/Sachets/Stick Packs:</strong>{" "}
                  Convenient and portable for consumers.
                </li>
                <li>
                  <strong>Bulk Packaging:</strong> Jars, cans, or buckets for
                  larger quantities.
                </li>
              </ul>
              <p>
                Our state-of-the-art facilities ensure that every product is
                packaged with precision, maintaining freshness, quality, and
                shelf stability.
              </p>
              <h3 className="text-start">Partner with Us</h3>
              <p className="text2">
                Whether you need gelatin or vegetarian capsules, Pharmaline is
                your trusted partner for high-quality manufacturing solutions.
                Our team is dedicated to understanding your needs and delivering
                products that exceed your expectations.
              </p>
              <p className="text2">
                “Contact us today to discuss your capsule manufacturing
                requirements and request a personalized quote.”
              </p>
            </div>
          </div>
        </div>
      ),
      Capsules: (
        <div className="InnerPageTabsContent">
          <div className="row">
            <div className="col-12 col-lg-6 mb-5">
              <h2 className="text-start">Capsules</h2>
              <p className="mb-3">
                At Pharmaline, we are equipped to efficiently handle medium and
                large production runs, ensuring timely delivery without
                compromising on quality. Our state-of-the-art facilities are GMP
                (Health Canada) approved, and we maintain rigorous on-site
                product quality control and quality assurance processes to meet
                the highest industry standards.
              </p>
              <h3 className="text-start">Why Choose Capsules?</h3>
              <p className="mb-3">
                Capsules are a preferred dosage form for many consumers due to
                their ease of swallowing and quick dissolution, which ensures
                increased absorption of the active ingredients. We specialize in
                producing high-quality capsules tailored to your specific needs.
              </p>
              <h3 className="text-start">
                Two-Piece Hard-Shell Gelatin Capsules
              </h3>
              <p className="mb-3">
                Two-piece hard-shell gelatin capsules are one of the most
                popular dosage forms in the dietary supplement industry. Our
                advanced manufacturing process ensures that every capsule is
                100% inspected for quality, guaranteeing that only the best
                products reach our customers. These capsules are ideal for a
                wide range of applications, including dietary supplements,
                vitamins, and over-the-counter medications.
              </p>
              <h3 className="text-start">Vegetarian Capsules</h3>
              <p className="mb-3">
                To meet the needs of our vegetarian-conscious customers, we
                offer 100% vegetarian capsules. Made from plant-based materials,
                these capsules are as effective as traditional gelatin-based
                options. Our experienced formulators will work closely with you
                to develop a fully vegetarian product that aligns with your
                brand and customer preferences.
              </p>
              <h3 className="text-start">Quality Assurance and Control</h3>
              <p className="mb-3">
                We are committed to delivering consistently pure and
                high-quality products. Our quality assurance and control
                measures include:
              </p>
              <ul className="CapsulesList">
                <li>
                  Rigorous testing of raw materials and finished products.
                </li>
                <li>
                  Minimizing waste through efficient production processes.
                </li>
                <li>
                  Sourcing empty capsules from trusted suppliers with a proven
                  track record of quality and reliability.
                </li>
              </ul>
              <p className="mb-3">
                These measures ensure that every product we manufacture meets
                the highest standards of safety, efficacy, and consistency.
              </p>
              {/* <div className="col-12 col-lg-6"> */}
              <h3 className="text-start">Partner with Us</h3>
              <p className="text2">
                Whether you need gelatin or vegetarian capsules, Pharmaline is
                your trusted partner for high-quality manufacturing solutions.
                Our team is dedicated to understanding your needs and delivering
                products that exceed your expectations.
              </p>
              <p className="text2">
                “Contact us today to discuss your capsule manufacturing
                requirements and request a personalized quote.”
              </p>
              {/* </div> */}
            </div>
            <div className="col-12 col-lg-6">
              <img src={capsules} className="CapsulesImage" alt="Capsules" />
              <img src={vegCapsule} className="blendingImage" alt="capsules" />
            </div>
            {/* <div className="row"> */}
            {/* <div className="col-12 col-lg-6">
                <img src={vegCapsule} className="blendingImage" />
              </div> */}
            {/* </div> */}
          </div>
        </div>
      ),
      Tablets: (
        <div className="InnerPageTabsContent">
          <div className="row">
            <div className="col-12 col-lg-6">
              <h2 className="text-start">Tablets</h2>
              <p className="mb-3">
                At Pharmaline, we specialize in producing high-quality tablets
                that meet the diverse needs of the dietary supplement and
                pharmaceutical industries. Our state-of-the-art facilities and
                rigorous quality assurance processes ensure that every tablet is
                consistently shaped, pure, and effective.
              </p>
              <h3 className="text-start">Quality Assurance and Control</h3>
              <p className="mb-3">
                We implement several quality assurance and control measures to
                guarantee the highest standards in tablet production. When
                formulating tablets, we carefully select the right excipients
                and lubricants in precise amounts to ensure optimal performance,
                stability, and bioavailability. Our commitment to quality
                ensures that every tablet meets your specifications and
                regulatory requirements.
              </p>
              <h3 className="text-start">Our Tablet Capabilities</h3>
              <p className="mb-3">
                We offer a comprehensive range of tablet options to suit your
                unique needs:
              </p>
              <ul className="TabletsList fw-bolder">
                <li>
                  Shapes and Sizes: From round to oval, and everything in
                  between, we can produce tablets in a variety of shapes and
                  sizes to match your branding and functional requirements.
                </li>
                <li>
                  Chewable and Flavored Formulations: Perfect for improving
                  patient compliance, our chewable and flavored tablets are
                  designed to be palatable and easy to consume.
                </li>
                <li>
                  Coatings: We provide a range of coating options, including
                  film coating, sugar coating, and enteric coating, to enhance
                  stability, appearance, and targeted release.
                </li>
                <li>
                  Special Formulas: We cater to diverse dietary preferences with
                  vegan, vegetarian, and Kosher-certified formulations.
                </li>
              </ul>
              <h3 className="text-start">
                Why Choose Pharmaline for Tablet Manufacturing?
              </h3>
              <ul className="TabletsList fw-bolder">
                <li>
                  GMP Compliance: Our facilities are GMP (Health Canada)
                  approved, ensuring the highest standards of quality and
                  safety.
                </li>
                <li>
                  Customization: We work closely with you to develop tablets
                  that meet your exact specifications, from formulation to
                  packaging.
                </li>
                <li>
                  Quality Guarantee: Our rigorous testing and quality control
                  processes ensure that every tablet is consistent, pure, and
                  effective.
                </li>
              </ul>
              <h3 className="text-start">Partner with Us</h3>
              <p className="text2">
                Whether you need gelatin or vegetarian capsules, Pharmaline is
                your trusted partner for high-quality manufacturing solutions.
                Our team is dedicated to understanding your needs and delivering
                products that exceed your expectations.
              </p>
              <p className="text2">
                “Contact us today to discuss your capsule manufacturing
                requirements and request a personalized quote.”
              </p>
            </div>
            <div className="col-12 col-lg-6">
              <img
                src={tablets}
                className="blendingImage d-none d-lg-block"
                alt="Tablets"
              />
              <img
                src={tabletsMobile}
                className="blendingImage d-lg-none"
                alt="Tablets Mobile"
              />
            </div>
            <div className="col-12 col-md-12 mt-5 mb-5">
              <img src={tabletsBottom} className="img-fluid w-100" />
            </div>
          </div>
        </div>
      ),
      TabletCoating: (
        <div className="InnerPageTabsContent">
          <div className="row">
            <div className="col-12 col-lg-6">
              <h2 className="text-start">Tablet coating</h2>
              <p>
                Tablet coating is an industry-standard process widely used for
                its cost-effectiveness and ability to enhance the functionality
                and appearance of tablets. At Pharmaline, we specialize in
                producing coated tablets that meet your exact specifications for
                size, hardness, disintegration, and color. Our state-of-the-art
                facilities and expertise ensure that every coated tablet
                delivers optimal performance and quality.
              </p>
              <h3 className="text-start">Why Choose Coated Tablets?</h3>
              <p>Coated tablets offer several advantages, including:</p>
              <ul className="TabletCoatingList">
                <li>
                  <strong>Prolonged Release of Active Ingredients:</strong>{" "}
                  Achieve controlled or delayed release for improved efficacy.
                </li>
                <li>
                  <strong>Protection of the Core:</strong> Shield the active
                  ingredients from moisture, light, and other environmental
                  factors.
                </li>
                <li>
                  <strong>Enhanced Appearance:</strong> Create a polished,
                  professional look that appeals to consumers.
                </li>
                <li>
                  <strong>Improved Stability:</strong> Extend shelf life and
                  maintain product integrity.
                </li>
                <li>
                  <strong>Ease of Packaging:</strong> Coated tablets are easier
                  to handle and package, streamlining your production process.
                </li>
              </ul>
              <h3 className="text-start">Our Tablet Coating Capabilities</h3>
              <p>
                We offer a comprehensive range of coating options to meet your
                specific needs:
              </p>
              <ul className="TabletCoatingList">
                <li>
                  <strong>Film Coating:</strong> A thin, uniform layer that
                  improves appearance and stability.
                </li>
                <li>
                  <strong>Sugar Coating:</strong> A traditional method that
                  provides a smooth, sweet finish.
                </li>
                <li>
                  <strong>Enteric Coating:</strong> Designed to resist stomach
                  acid, ensuring the tablet dissolves in the intestines for
                  targeted release.
                </li>
                <li>
                  <strong>Custom Coatings:</strong> Tailored solutions to meet
                  unique formulation requirements, including whey protein-based
                  coatings for specialized applications.
                </li>
              </ul>
              <h3 className="text-start">
                On-Site Manufacturing and Quality Assurance
              </h3>
              <p>
                All stages of tablet production—dispensing, blending, coating,
                and packaging—are conducted on-site within our extensive
                cleanroom facility. This integrated approach ensures strict
                quality control and efficiency.
              </p>
              <ul className="TabletCoatingList">
                <li>
                  <strong>Customization:</strong> We can manufacture tablets in
                  a wide range of sizes, shapes, and colors to fit your branding
                  and functional requirements.
                </li>
                <li>
                  <strong>Quality Guarantee:</strong> Our GMP (Health Canada)
                  approved facilities and rigorous quality assurance processes
                  ensure that every coated tablet meets the highest standards of
                  purity, consistency, and performance.
                </li>
              </ul>
              <h3 className="text-start">Partner with Us</h3>
              <p className="text2">
                Whether you need gelatin or vegetarian capsules, Pharmaline is
                your trusted partner for high-quality manufacturing solutions.
                Our team is dedicated to understanding your needs and delivering
                products that exceed your expectations.
              </p>
              <p className="text2">
                “Contact us today to discuss your capsule manufacturing
                requirements and request a personalized quote.”
              </p>
            </div>
            {/* <div className="col-12 col-lg-4">
              <ul className="TabletCoatingList">
                <li>Prolonged release of drug</li>
                <li>Protecting the core</li>
                <li>Promising appearance</li>
                <li>Packing made simple</li>
                <li>Stability</li>
                <li>Whey Proteins</li>
              </ul>
            </div> */}
            {/* <div className="row my-5">
              <div
                className="col-md-12"
                style={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  height: "0",
                }}
              >
                <iframe
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  }}
                  src="https://www.youtube.com/embed/JGqKfUxIiwk?si=ThaG6w520SibWBud"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </div> */}
            <div className="col-12 col-lg-6">
              <img src={tabletCoating} className="CapsulesImage" alt="Capsules" />
            </div>
          </div>
        </div>
      ),
      ContractManufacturing: (
        <div className="InnerPageTabsContent">
          <div className="row">
            <div className="col-12 col-lg-6">
              <h2 className="text-start">Contract Manufacturing</h2>
              <p className="mb-3">
                At Pharmaline, we take pride in being your trusted partner for
                high-quality supplement manufacturing. With the most innovative
                nutraceutical manufacturing machinery available, we are equipped
                to bring your product vision to life. Whether you need tablets,
                powders, or capsules, we manufacture each product to your exact
                specifications, ensuring it aligns with your marketing goals and
                brand identity.
              </p>
              <h3 className="text-start">
                Why Choose Pharmaline for Contract Manufacturing?
              </h3>
              <p className="mb-3">
                State-of-the-Art Facilities: Our advanced manufacturing
                machinery and GMP (Health Canada) approved facilities ensure the
                highest standards of quality and efficiency.
              </p>
              <ul className="TabletCoatingList">
                <li>
                  <strong>Customization:</strong> We work closely with you to
                  create products that meet your unique formulation, packaging,
                  and branding requirements.
                </li>
                <li>
                  <strong>Flexible Solutions:</strong> Whether you’re looking
                  for a collaborative partnership or a turn-key solution, we
                  adapt to your needs.
                </li>
                <li>
                  <strong>End-to-End Support:</strong> From formulation and
                  production to packaging and quality assurance, we handle every
                  step of the process.
                </li>
              </ul>
              <h3 className="text-start">Our Commitment to You</h3>
              <p className="mb-3">
                We understand that your success is our success. That’s why we
                are committed to:
              </p>
              <ul className="TabletCoatingList">
                <li>
                  Delivering products that meet your exact specifications.
                </li>
                <li>
                  Ensuring consistent quality and compliance with regulatory
                  standards.
                </li>
                <li>
                  Providing flexible solutions that fit your business model.
                </li>
                <li>
                  Building a long-term partnership based on trust and
                  collaboration.
                </li>
              </ul>
              <h3 className="text-start">Partner with Us</h3>
              <p className="text2">
                Whether you need gelatin or vegetarian capsules, Pharmaline is
                your trusted partner for high-quality manufacturing solutions.
                Our team is dedicated to understanding your needs and delivering
                products that exceed your expectations.
              </p>
              <p className="text2">
                “Contact us today to discuss your capsule manufacturing
                requirements and request a personalized quote.”
              </p>
            </div>
            <div className="col-12 col-lg-6">
              <img
                src={contract}
                className="blendingImage"
                alt="Contract Manufacturing"
              />
            </div>
          </div>
        </div>
      ),
    };

    return tabContents[activeTab];
  };

  return (
    <>
      <div className="manufactureBanner">
        <div className="container">
          <h2>Manufacturing services</h2>
          <p>
            <i className="icon-lineArrowBottom"></i>
          </p>
          <h3>
            We are equipped to efficiently handle medium and large production
            runs.{" "}
          </h3>
          <p>
            Our sites are GMP (Health Canada) approved. We have on site product
            quality control and quality assurance.{" "}
          </p>
        </div>
      </div>

      <div className="InnerPageBody p-0" id="tabs" >
        <div className="prodctUnits container">
          <div className="prodctUnitscontainer">
            <div className="row">
              <div className="col-12 col-lg-12">
                <div className="manufactureLinks">
                  <div className="innerPageTabs">
                    <ul
                      className="nav nav-tabs MSTabs mobile-tabs container"
                      role="tablist"
                      id="topFixedTabs"
                      onClick={toggleMenu}
                    >
                      {tabs.map((tab) => (
                        <li
                          key={tab.id}
                          className={`nav-item ${
                            isMenuCollapsed && activeTab !== tab.id
                              ? "d-none d-md-block"
                              : ""
                          } ${activeTab === tab.id ? "active" : ""}`}
                          role="tab"
                        >
                          <a
                            className={`nav-link ${
                              activeTab === tab.id ? "active" : ""
                            }`}
                            role="tab"
                            data-bs-toggle="tab"
                            href={`#${tab.id}`}
                            aria-controls={tab.id}
                            onClick={(e) => {
                              e.preventDefault();
                              handleTabChange(tab.id);
                            }}
                          >
                            {tab.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="rightcontent">
                    <div
                      className={`tab-pane fade tab-style ml-3 mt-3 ${
                        activeTab ? "active show" : ""
                      }`}
                      id={activeTab}
                      role="tabpanel"
                    >
                      {renderTabContent()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ManufacturingServices;
