import React, { useEffect, useState } from "react";
import milestone from "../assets/images/Milestone.jpg";
import team1 from "../assets/images/team/ABHISHEK SONI.jpg";
import team2 from "../assets/images/team/Himadarshini Akanambattu.jpg";
import team3 from "../assets/images/team/KATAN MEHTHA.jpg";
import team4 from "../assets/images/team/RAGHURAM NAIDU ARRIGELA.jpg";
import team5 from "../assets/images/team/RAMU-DONAKANTI.jpg";
import team6 from "../assets/images/team/SCOTT-FARRELL.jpg";
import team7 from "../assets/images/team/SWATHI-KOMMAREDDY.jpg";
import team8 from "../assets/images/team/ramesh_kolla.png";
import team9 from "../assets/images/team/dennis.png";
import team10 from "../assets/images/team/Masum.jpg";
import team11 from "../assets/images/team/Ram.jpg";
import team12 from "../assets/images/team/Hemanth.jpg";
import placeholderimg from "../assets/images/team/placeholder_image.png";

const Overview = () => {
  const [activeOverviewTab, setActiveOverviewTab] = useState("CompanyOverview");
  const [activeManufacturingTab, setActiveManufacturingTab] =
    useState("ManufacturingUnits");

  const teamMembers = [
    {
      id: 0,
      name: "Suresh Kolla",
      position: "Founder, CEO",
      department: "",
      image: team8,
    },
    {
      id: 1,
      name: "Denis K. Ivic",
      position: "Vice President",
      department: "Brand Management",
      image: team9,
    },
    {
      id: 2,
      name: "Scott Farrell",
      position: "Vice President",
      department: "Sales and Development",
      image: team6,
    },
    {
      id: 3,
      name: "Abhishek Soni",
      position: "Vice President",
      department: "Retail Chain Operations",
      image: team1,
    },
    {
      id: 4,
      name: "Raghuram Naidu Arigela",
      position: "General Manager, HR & Finance",
      department: "",
      image: team11,
    },
    {
      id: 5,
      name: "Masum",
      position: "Operations Manager",
      department: "",
      image: team10,
    },
    {
      id: 6,
      name: "Ramu Donakanti",
      position: "Plant Manager",
      department: "",
      image: team5,
    },
    {
      id: 7,
      name: "Himadarshini Akanambattu",
      position: "QA & Regulatory Affairs",
      department: "",
      image: team2,
    },
    {
      id: 8,
      name: "Katan Mehta",
      position: "Technical Manager",
      department: "",
      image: team3,
    },
    {
      id: 9,
      name: "Hemanth",
      position: "Quality Control Manager",
      department: "",
      image: team12,
    },
    {
      id: 10,
      name: "Swathi Kommareddy",
      position: "Accounts Manager",
      department: "",
      image: team7,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on load
  }, []);

  return (
    <>
      <div className="InnerPageBanner" style={{ marginTop: "100px" }}>
        <div className="InnerBannerTitle">
          Innovation
          <p>"We are a reliable and competent partner you can trust."</p>
        </div>
      </div>

      <div className="InnerPageBody p-0">
        <div className="overviewContent">
          <div className="left">
            <ul
              className="nav nav-tabs newTabs mobile-tabs"
              id="overviewTab"
              role="tablist"
            >
              <li className="nav-item active" role="presentation">
                <a
                  className={`nav-link ${
                    activeOverviewTab === "CompanyOverview" ? "active" : ""
                  }`}
                  onClick={() => setActiveOverviewTab("CompanyOverview")}
                  role="tab"
                >
                  Company Overview <i className="icon-arrow_tale_right"></i>
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className={`nav-link ${
                    activeOverviewTab === "Vision" ? "active" : ""
                  }`}
                  onClick={() => setActiveOverviewTab("Vision")}
                  role="tab"
                >
                  Vision & Mission <i className="icon-arrow_tale_right"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="right">
            <div className="rightContent">
              <div className="tab-content">
                <div
                  className={`tab-pane fade ${
                    activeOverviewTab === "CompanyOverview" ? "show active" : ""
                  }`}
                  role="tabpanel"
                >
                  <h2 className="text-left">Overview</h2>
                  <div className="previewContent scrollable">
                    <p>
                      <strong>Pharmaline Inc</strong> is a Nutraceutical and
                      Supplement manufacturer specializing in full service
                      private label manufacturing, packaging as well as
                      exporting our brands worldwide. As a dietary supplements
                      manufacturer, we handle a wide variety of dosage forms and
                      pride ourselves on delivering collaborative, proactive and
                      dependable service.
                    </p>
                    <p>
                      Our facility is equipped to manufacture oral solid forms
                      as capsules, tablets and powders in jars. Our tablet and
                      encapsulation capability is comprehensive, with a full
                      range of shapes and sizes. Pharmaline specializes in the
                      contract supplement packaging of Dietary Supplements with
                      a wide range of packaging and bottling options including
                      unit packing of sport nutritionals. With full control over
                      service, quality and reliability all under one roof, we
                      provide one vendor convenience to our customers. We
                      continually provide innovative solutions to meet growing
                      demands and deliver to you the highest quality at the most
                      competitive pricing.
                    </p>
                  </div>
                </div>
                <div
                  className={`tab-pane fade ${
                    activeOverviewTab === "Vision" ? "show active" : ""
                  }`}
                  role="tabpanel"
                >
                  <h2 className="text-left">Our Vision</h2>
                  <div className="previewContent scrollable">
                    <p>
                      At <strong>Pharmaline Inc</strong>., we are committed to
                      exceeding customer expectations by applying focused and
                      agile solutions. Our goal is to design, create, and
                      deliver customized products in partnership with each
                      customer—turning conceptual ideas into successful
                      realities.
                    </p>
                    {/* <p>
                      We are focused on supplying high quality products and
                      services to emerging global healthcare markets and focus
                      on international expansion as a Canadian based healthcare
                      company. Through our worldwide operations, we under-stand
                      our customer's needs and add value to their business by
                      offering attractively priced high-quality products &
                      services. We strive to work towards long-term
                      sustainability and provide world-class customer service.
                    </p> */}
                  </div>
                  <h2 className="text-left">Our Mission</h2>
                  <div className="previewContent scrollable">
                    <p>As a Canadian-based healthcare company, we focus on:</p>
                    <p>
                      ✔ Delivering high-quality products and services to
                      emerging global healthcare markets
                    </p>
                    <p>
                      ✔ Expanding internationally to support growing demands
                    </p>
                    <p>
                      ✔ Understanding customer needs worldwide and adding value
                      through competitively priced, superior-quality products
                    </p>
                    <p>
                      ✔ Ensuring long-term sustainability while maintaining
                      world-class customer service
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ourJourney">
          <div className="left">
            <h2>Our Journey</h2>
            <h3>18 Years of Excellence, Growth, and Innovation</h3>
            <p>
              From humble beginnings to a globally recognized nutraceutical
              leader, we have continuously evolved to meet industry demands.
              With a commitment to quality and innovation, we empower our
              partners, driving success through cutting-edge solutions and
              unmatched expertise.
            </p>
            <p>
              📍 <strong>Company Highlights</strong>
            </p>
            <p>
              🏢 <strong>35,000 Sq. Ft.</strong> – Total Facility Area
            </p>
            <p>
              📍 <strong>3 Locations</strong> – Expanding Our Global Reach
            </p>
            <p>
              ✅ <strong>315 Active NPN Licenses</strong> – A Commitment to
              Compliance
            </p>
            <p>
              🔄 <strong>60 In-Process NPNs</strong> – Constant Innovation
            </p>
            <p>
              🌱 <strong>30 New NPNs in the Pipeline</strong> – Future Growth
            </p>
          </div>
          <div className="right">
            <img src={milestone} alt="Our Journey" className="mapImg" />
          </div>
        </div>

        <div className="ourProducts">
          <div >
            <div className="container">
              <div className="prodctUnitsRow">
                <div>
                  <div className="unitsBg">
                    <h2>
                      35,000 <span className="small">Sq ft.</span>
                    </h2>
                    <p>Total Area</p>
                  </div>
                </div>
                <div>
                  <div className="unitsBg">
                    <h2>3</h2>
                    <p>Locations</p>
                  </div>
                </div>
                <div>
                  <div className="unitsBg">
                    <h2>315</h2>
                    <p>Active NPN Licenses</p>
                  </div>
                </div>
                <div>
                  <div className="unitsBg">
                    <h2>60</h2>
                    <p>In Process NPNs</p>
                  </div>
                </div>
                <div>
                  <div className="unitsBg">
                    <h2>30</h2>
                    <p>New NPNs Pipeline</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="prodctUnits">
          <div className="container">
            {/* <div className="container mt-5">
              <h2 className="text-center mb-4 ourTeam">Meet Our Team</h2>
              <div className="row justify-content-center">
                {teamMembers.map((member, index) => (
                  <div
                    key={member.id}
                    // className="col-6 col-sm-4 col-md-3 col-lg-2 mb-4 d-flex"
                    className={`col-6 col-sm-4 col-md-3 mb-4 d-flex ${
                      index < 4 ? "order-1" : "order-2"
                    }`}
                  >
                    <div className="card text-center shadow-sm h-100 w-100">
                      <img
                        src={member.image}
                        alt={member.name}
                        className="card-img-top rounded"
                      />
                      <div className="card-body">
                        <h5 className="card-title">{member.name}</h5>
                        <p>{member.position}</p>
                        <p>{member.department}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div> */}
            <div className="container" style={{ marginTop: "80px" }}>
              <h2 className="text-center mb-4 ourTeam">Meet Our Team</h2>
              <div className="row justify-content-center">
                {/* First Row: CEO (Only One Card) */}
                <div className="col-12 d-flex justify-content-center mb-4">
                  <div
                    className="card text-center shadow-sm h-100 w-100"
                    style={{ maxWidth: "18rem" }}
                  >
                    <img
                      src={teamMembers[0].image}
                      alt={teamMembers[0].name}
                      className="card-img-top rounded"
                    />
                    <div className="card-body">
                      <h5 className="card-title member">
                        {teamMembers[0].name}
                      </h5>
                      <p className="member" style={{ marginBottom: "0" }}>
                        {teamMembers[0].position}
                      </p>
                      <p className="member">{teamMembers[0].department}</p>
                    </div>
                  </div>
                </div>

                {/* Remaining Team Members */}
                {teamMembers.slice(1).map((member) => (
                  <div
                    key={member.id}
                    className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center"
                  >
                    <div
                      className="card text-center shadow-sm h-100 w-100"
                      style={{ maxWidth: "18rem" }}
                    >
                      <img
                        src={member.image}
                        alt={member.name}
                        className="card-img-top rounded"
                      />
                      <div className="card-body">
                        <h5 className="card-title">{member.name}</h5>
                        <p style={{ marginBottom: "0" }}>{member.position}</p>
                        <p>{member.department}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* <div className="row">
              <div className="col-12 col-lg-12">
                <div className="unitsTabs">
                  <ul className="nav nav-tabs MSTabs" role="tablist">
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          activeManufacturingTab === "ManufacturingUnits"
                            ? "active"
                            : ""
                        }`}
                        onClick={() =>
                          setActiveManufacturingTab("ManufacturingUnits")
                        }
                        role="tab"
                      >
                        Manufacturing Units
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          activeManufacturingTab === "PackingUnits"
                            ? "active"
                            : ""
                        }`}
                        onClick={() =>
                          setActiveManufacturingTab("PackingUnits")
                        }
                        role="tab"
                      >
                        Packing Units
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className={`tab-pane fade ${
                        activeManufacturingTab === "ManufacturingUnits"
                          ? "show active"
                          : ""
                      }`}
                      role="tabpanel"
                    >
                      <div className="manufacturingContent">
                        <iframe
                          width="100%"
                          height="440"
                          src="https://www.youtube.com/embed/Nh_UywRlErQ?si=cMeonS6Z_n-mnuzF"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin"
                          allowFullScreen
                        />
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeManufacturingTab === "PackingUnits"
                          ? "show active"
                          : ""
                      }`}
                      role="tabpanel"
                    >
                      <div className="manufacturingContent">
                        <iframe
                          width="100%"
                          height="440"
                          src="https://www.youtube.com/embed/w2H4q1G8q0M?si=ztZLKdH5KJBI5qp1"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin"
                          allowFullScreen
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
