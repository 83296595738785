import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import slide4 from "../assets/images/Slidev3_01_02.jpg";
import slide2 from "../assets/images/Slidev3_02_01.jpg";
import slide3 from "../assets/images/Slidev3_03_01.jpg";
import slide1 from "../assets/images/Slidev3_05_01.jpg";

const SliderCarousel = () => {
    return (
        <Carousel showThumbs={false} showStatus={false} autoPlay infiniteLoop>
            <div style={{ cursor: "pointer" }} onClick={() => window.location.href = "/Overview"}>
                <img src={slide1} />
            </div>
            <div>
                <img src={slide2} />
            </div>
            <div>
                <img src={slide3} />
            </div>
            <div>
                <img src={slide4} />
            </div>
        </Carousel>
    )
}

export default SliderCarousel