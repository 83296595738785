import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem("token");
  useEffect(() => {
    // If there's no token, remove it and redirect to login
    if (!token) {
      localStorage.removeItem("token");
    }
  }, [token]);
  return token ? children : <Navigate to="/Login" />;
};

export default PrivateRoute;
