import React from "react";
import "../css/menu.css";
import "../css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <>
      <div className="footerBlock">
        <div className="footer-top"></div>
        <div className="footer">
          <div className="footerRow container">
            <div className="row">
              {/* Address Section */}
              <div className="col-12 col-md-6 col-lg-3">
                <h4>Address</h4>
                <div className="footercontact">
                  <div className="FTicon">
                    <i className="icon-location"></i>
                  </div>
                  <div className="FTText">
                    UNIT 5 2835 Argentina Road Mississauga L5N 8G6
                  </div>
                </div>
                <div className="footercontact">
                  <div className="FTicon">
                    <i className="icon-call"></i>
                  </div>
                  <div className="FTText">
                    <p>Ph: +1-905 812 3916</p>
                    <p>Fax: +1-905-248-5404</p>
                  </div>
                </div>
                <div className="footercontact">
                  <div className="FTicon">
                    <i className="icon-email"></i>
                  </div>
                  <div className="FTText" style={{ marginTop: "10px" }}>
                    <a href="mailto:sales@pharmalineinc.com">
                      sales@pharmalineinc.com
                    </a>
                  </div>
                </div>
              </div>
              {/* Manufacturing Services */}
              <div className="col-12 col-md-6 col-lg-3">
                <h4>Manufacturing services</h4>
                <ul className="FTLinks">
                <li>
                  <Link to ={"/manufacturingservices/capsules"}>Capsules</Link>
                  </li>
                  <li>
                  <Link to ={"/manufacturingservices/tablets"}>Tablets</Link>
                  </li>
                  <li>
                  <Link to ={"/manufacturingservices/tablet-coating"}>Tablet Coating</Link>
                  </li>
                  <li>
                    <Link to ={"/manufacturingservices/blending"}>Pouches/Sachets/Stickpack</Link>
                  </li>  
                  <li>
                  <Link to ={"/manufacturingservices/contract-manufacturing"}>
                      Contract Manufacturing
                      </Link>
                  </li>
                </ul>
              </div>
              {/* Packaging Services */}
              <div className="col-12 col-md-6 col-lg-3">
                <h4>Packaging services</h4>
                <ul className="FTLinks">
                  <li>
                  <Link to ={"/packagingservices/blister-packing"}>Blister Packing</Link>
                  </li>
                  <li>
                  <Link to ={"/packagingservices/powder-filling"}>Powder filling</Link>
                  </li>
                  <li>
                  <Link to ={"/packagingservices/bottling"}>Bottling</Link>
                  </li>
                </ul>
              </div>
              {/* Quick Links */}
              <div className="col-12 col-md-6 col-lg-3">
                <h4>Quick links</h4>
                <ul className="FTLinks">
                  <li>
                  <Link to ={"/Overview"}>About us</Link>
                  </li>
                  <li>
                  <Link to ={"/contactus"}>Contact us</Link>
                  </li>
                  <li>
                    <a href="https://online.pubhtml5.com/ynyys/wpbd/" target="_blank" rel="noopener noreferrer">Catalog</a>

                  </li>
                  <li>
                  <Link to ={"/ProductsList"}>All Products</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="BottomBtn">
        <a href="#" className="BottomGetQuote">
          Get quote
        </a>
      </div>
      <div className="copyrightRow">
        Copyright © {new Date().getFullYear()}. All Rights Reserved.
      </div>
    </>
  );
};

export default Footer;
