import axios from "axios";
import React, { useState, useEffect } from "react";
import { PieChart } from "react-minimal-pie-chart";

const GetQuote = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [showSummary, setShowSummary] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const initialFormState = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    website: "",
    area_of_marketing: "",
    product: "",
    agreeToProvideDocuments: "",
    document: null,
    formula_details: "",
    documentationSupport: "",
    documents_required: "",
    selectedItems: "",
    packtype: "",
    enter_bottle: "",
    packsize: "",
    bottle_quantity: "",
    additionalinfo: "",
    accurate_info: "",
  };

  const [formData, setFormData] = useState(initialFormState);

  const steps = [
    {
      label: "Step 1",
      fields: [
        {
          label: "First Name",
          name: "firstName",
          type: "text",
          placeholder: "First Name",
          required: true,
        },
        {
          label: "Last Name",
          name: "lastName",
          type: "text",
          placeholder: "Last Name",
          required: true,
        },
        {
          label: "Email",
          name: "email",
          type: "email",
          placeholder: "Email",
          required: true,
        },
        {
          label: "Phone",
          name: "phone",
          type: "number",
          placeholder: "Phone",
          required: true,
        },
        {
          label: "Company",
          name: "company",
          type: "text",
          placeholder: "Company",
          required: true,
        },
        {
          label: "Website",
          name: "website",
          type: "text",
          placeholder: "Website",
          required: true,
        },
        {
          label: "Area of marketing",
          name: "area_of_marketing",
          type: "select",
          options: ["United States", "Canada", "Other"],
          required: false,
        },
        {
          label: "Product looking for",
          name: "product",
          type: "select",
          options: ["Custom Formula", "Pharmaline Formula"],
          required: false,
        },
      ],
    },
    {
      label: "Step 2",
      fields: [
        {
          label: "Do you agree to provide additional documents?",
          name: "agreeToProvideDocuments",
          type: "radio",
          options: ["Yes", "No"],
          required: true,
        },
        {
          label: "Attach Document",
          name: "document",
          type: "file",
          accept: ".pdf,.doc,.docx",
          required: false,
        },
        {
          label: "Formula Details",
          name: "formula_details",
          type: "text",
          placeholder: "Enter or paste custom formula details",
          required: false,
        },
      ],
    },
    {
      label: "Step 3",
      fields: [
        {
          label: "Documentation Support",
          name: "documentationSupport",
          type: "radio",
          options: ["Yes", "No"],
          required: false,
        },
        {
          label: "Documents Required",
          name: "documents_required",
          type: "text",
          placeholder: "Please mention what documents are required",
          required: false,
        },
        {
          label: "Select Dosage form",
          name: "selectedItems",
          type: "select",
          options: [
            "Select Dosage form",
            "Capsules",
            "Tablets",
            "Softgels",
            "Powder",
            "Gummies",
          ],
          required: false,
        },
      ],
    },
    {
      label: "Step 4",
      fields: [
        {
          label: "Pack Type",
          name: "packtype",
          type: "select",
          options: ["Pack type", "Bottles", "Blisters in carton box"],
          required: false,
        },
        {
          label: "Enter bottle",
          name: "enter_bottle",
          type: "text",
          placeholder:
            "Please enter bottle / cap specifications like, HDPE/PET/Color",
          required: false,
        },
        {
          label: "Pack size",
          name: "packsize",
          type: "select",
          options: ["Select pack size", "30", "60", "90", "Custom size"],
          required: false,
        },
      ],
    },
    {
      label: "Step 5",
      fields: [
        {
          label: "Bottle Quantity",
          name: "bottle_quantity",
          type: "number",
          placeholder: "Please provide the quantity",
          required: false,
        },
        {
          label: "Documentation Support",
          name: "documentation_support",
          type: "radio",
          options: ["Yes", "No"],
          required: false,
        },
        {
          label: "Additional Info",
          name: "additionalinfo",
          type: "text",
          placeholder:
            "To ensure accurate understanding and effective delivery, kindly provide more details with your requirements.",
          required: false,
        },
        {
          label: "Accurate info",
          name: "accurate_info",
          type: "text",
          placeholder:
            "Please provide any additional information to help us in providing you more accurate quote",
          required: false,
        },
      ],
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on load
  }, []);
  
  const totalSteps = steps.length;

  const validateStep = (step) => {
    const errors = {};
    steps[step].fields.forEach((field) => {
      if (field.required && !formData[field.name].trim()) {
        errors[field.name] = `${field.label} is required`;
      }
    });
    return errors;
  };

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    
    if (type === "file") {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleNext = () => {
    const errors = validateStep(currentStep);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    
    setFormErrors({});
    if (currentStep < totalSteps - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setShowSummary(true);
    }
  };

  const handlePrevious = () => {
    setFormErrors({});
    if (currentStep > 0) setCurrentStep(currentStep - 1);
  };

  const handleFinalSubmit = async () => {
    setIsSubmitting(true);
    try {
      const formPayload = new FormData();
      
      Object.entries(formData).forEach(([key, value]) => {
        if (value instanceof File) {
          formPayload.append(key, value);
        } else if (value !== null && value !== undefined) {
          formPayload.append(key, value);
        }
      });

      // await axios.post(
      //   `${process.env.REACT_APP_BASE_URL}/get-quote`,
      //   formPayload,
      //   { headers: { "Content-Type": "multipart/form-data" } }
      // );

      setApiSuccess(true);
      setShowSummary(false);
      setFormData(initialFormState);
      setCurrentStep(0);
    } catch (error) {
      alert("Failed to submit the quote request. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const SummaryView = () => (
    <div className="summary-view text-dark">
      <h2>Review Your Information</h2>
      <div className="summary-content">
        {Object.entries(formData).map(([key, value]) => {
          if (!value) return null;
          
          const fieldConfig = steps
            .flatMap(step => step.fields)
            .find(field => field.name === key);

          return (
            <div key={key} className="summary-item">
              <strong>{fieldConfig?.label || key.replace(/_/g, ' ')}:</strong>
              <span>
                {value instanceof File ? value.name : 
                 Array.isArray(value) ? value.join(', ') : 
                 value.toString()}
              </span>
            </div>
          );
        })}
      </div>
      <div className="summary-actions">
        <button
          type="button"
          className="redBtn mr-2"
          onClick={() => setShowSummary(false)}
          disabled={isSubmitting}
          style={{
            backgroundColor: "#040533",
            border: "1px solid #040533",
            marginRight: "10px",
            width: "150px",
          }}
        >
          Edit
        </button>
        <button
          type="button"
          className="redBtn  mr-2"
          onClick={handleFinalSubmit}
          disabled={isSubmitting}
          style={{
            marginRight: "10px",
            width: "150px",
          }}
        >
          {isSubmitting ? 'Submitting...' : 'Confirm'}
        </button>
      </div>
    </div>
  );

  const progressPercentage = showSummary ? 100 : Math.round(((currentStep + 1) / totalSteps) * 100);

  return (
    <>
      <div className="InnerPageBanner" style={{ marginTop: "100px" }}>
        <div className="contactBanner">Get in touch with us!</div>
      </div>

      <div className="InnerPageBody p-0">
        <div className="contactContent">
          <div className="left">
            <div className="contactInfo">
              <h3>Wants to speak</h3>
              <h4>Contact us</h4>
              <p>Office: +1-905 812 3916</p>
              <p>FAX: +1-905-248-5404</p>
              <p>Email: sales@pharmalineinc.com</p>
            </div>
            <div className="contactInfo">
              <h4>Office hours</h4>
              <p>Mon- Fri: 8 am to 5 pm (EST)</p>
            </div>
            <div className="contactInfo">
              <h4>Address</h4>
              <p>Unit 5 2835 Argentia Road Mississauga ON, Canada L5N 8G6</p>
            </div>
          </div>

          <div className="right">
            <div className="contactForm">
            { !apiSuccess ? (
              <>
            <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "30px",
                }}
              >
                <h1>Get Quote</h1>
                <div style={{ width: "100px" }}>
                  <PieChart
                    data={[
                      { value: progressPercentage, color: "#4caf50" },
                      { value: 100 - progressPercentage, color: "red" },
                    ]}
                    lineWidth={30}
                    rounded
                    totalValue={100}
                    startAngle={270}
                  />
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "-65px",
                      fontSize: "18px",
                      color: "black",
                    }}
                  >
                    {progressPercentage}%
                  </div>
                </div>
              </div>
              <h3>
                With simple steps, fill out the form below, and we will shortly
                respond to your quote.
              </h3>
              </>
            ) : <></>}

              {apiSuccess ? (
                <div className="success-message">
                  <h3>Thank you for reaching out!</h3>
                  <h3>Our support team will contact you within 12-24 hours.</h3>
                  <button
                    className="redBtn  mr-2"
                    onClick={() => setApiSuccess(false)}
                    style={{
                      marginRight: "10px",
                      width: "150px",
                    }}
                  >
                    Submit New Quote
                  </button>
                </div>
              ) : showSummary ? (
                <SummaryView />
              ) : (
                <form onSubmit={(e) => {
                  e.preventDefault();
                  handleNext();
                }}>
                  <div className="container">
                    <div className="row">
                      {steps[currentStep].fields.map((field, index) => (
                        <div
                        className={`${
                          currentStep === 0 ? "col-md-6 mb-3" : "col-12"
                        }`}
                        key={index}
                        style={currentStep === 0 ? {} : { width: "100%" }}
                      >
                          <label className="form-label text-dark">{field.label}</label>
                          
                          {formErrors[field.name] && (
                            <div className="text-danger small">
                              {formErrors[field.name]}
                            </div>
                          )}

                          {/* Field rendering logic */}
                          {field.type === "select" ? (
                            <select
                              className={`form-control ${formErrors[field.name] ? 'is-invalid' : ''}`}
                              name={field.name}
                              value={formData[field.name]}
                              onChange={handleInputChange}
                            >
                              {field.options.map((option, idx) => (
                                <option key={idx} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          ) : field.type === "radio" ? (
                            <div className="radio-group text-dark">
                              {field.options.map((option, idx) => (
                                <div key={idx} className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={field.name}
                                    id={`${field.name}-${idx}`}
                                    value={option}
                                    checked={formData[field.name] === option}
                                    onChange={handleInputChange}
                                  />
                                  <label className="form-check-label" htmlFor={`${field.name}-${idx}`}>
                                    {option}
                                  </label>
                                </div>
                              ))}
                            </div>
                          ) : field.type === "file" ? (
                            <input
                              type="file"
                              className={`form-control ${formErrors[field.name] ? 'is-invalid' : ''}`}
                              name={field.name}
                              onChange={handleInputChange}
                            />
                          ) : (
                            <input
                              type={field.type}
                              className={`form-control ${formErrors[field.name] ? 'is-invalid' : ''}`}
                              name={field.name}
                              value={formData[field.name]}
                              onChange={handleInputChange}
                              placeholder={field.placeholder}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="form-navigation">
                  <div
                      className="col-12 text-right"
                      style={{ display: "flex", justifyContent: "right" }}
                    >
                    {currentStep > 0 && (
                      <button
                        type="button"
                        className="redBtn mr-2"
                        onClick={handlePrevious}
                        style={{
                          backgroundColor: "#040533",
                          border: "1px solid #040533",
                          marginRight: "10px",
                          width: "150px",
                        }}
                      >
                        Back
                      </button>
                    )}
                    
                    <button
                      type="submit"
                      className="redBtn  mr-2"
                      disabled={isSubmitting}
                      style={{
                        marginRight: "10px",
                        width: "150px",
                      }}
                    >
                      {currentStep === totalSteps - 1 ? 'Review' : 'Next'}
                    </button>
                  </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetQuote;