import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "../css/menu.css";
import "../css/fonts/IconFonts/css/pharmalinefonts.css";
import profilePic from "../assets/images/aboutProfilePic.png";
import logoDesktop from "../assets/images/LogoSVG.svg";
import logoMobile from "../assets/images/LogoIpadSVG.svg";
import logoMobileSm from "../assets/images/LogoSM.svg";
import catalogImg from "../assets/images/productListImg.png";
import searchIcon from "../assets/images/searchIcon.png";
import ironSvg from "../assets/images/svg/Iron.svg";
import sportsSvg from "../assets/images/svg/Sports.svg";

const Navbar = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const productMenu = [
    {
      href: "productsList/HeartHealth",
      icon: "icon-heart",
      text: "Heart health",
    },
    {
      href: "productsList/SleepSupport",
      icon: "icon-sleep",
      text: "Sleep support",
    },
    {
      href: "productsList/IronSupplements",
      icon: "",
      svgIcon: ironSvg,
      text: "Iron Supplements",
    },
    {
      href: "productsList/ImmuneSupport",
      icon: "icon-immune",
      text: "Immune support",
    },
    {
      href: "productsList/Bone&JointHealth",
      icon: "icon-bonehealth",
      text: "Bone support",
    },
    {
      href: "productsList/DigestiveSupport",
      icon: "icon-digestive",
      text: "Digestive support",
    },
    {
      href: "productsList/MoodSupport",
      icon: "icon-mood",
      text: "Mood support",
    },
    {
      href: "productsList/Women’sHealth",
      icon: "icon-womens_health",
      text: "Women's health",
    },
    {
      href: "productsList/SportNutrition",
      icon: "",
      svgIcon: sportsSvg,
      text: "Sports",
    },
    {
      href: "productsList/WeightManagement",
      icon: "icon-overweight",
      text: "Weight management",
    },
    {
      href: "productsList/Gummies",
      icon: "icon-gummies",
      text: "Gummies",
    },
    {
      href: "productsList/MensHealth",
      icon: "icon-menshealth",
      text: "Men's Health",
    },
  ];
  const location = useLocation();
  const [isDesktop, setIsDesktop] = useState(false);

  // Detect desktop viewport
  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1024px)');
    const handleMediaChange = (e) => setIsDesktop(e.matches);
    mediaQuery.addEventListener('change', handleMediaChange);
    setIsDesktop(mediaQuery.matches);
    return () => mediaQuery.removeEventListener('change', handleMediaChange);
  }, []);

  // Close menu when route changes
  useEffect(() => {
    setIsMenuActive(false);
    setActiveSubmenu(null);
  }, [location]);

  const toggleMenu = () => {
    setIsMenuActive(!isMenuActive);
  };

  const toggleSubmenu = (submenuName) => {
    setActiveSubmenu(prevSubmenu => 
      prevSubmenu === submenuName ? null : submenuName
    );
    setIsMenuActive(true);
  };

  const handleMenuHeaderClick = () => {
    // Only reset submenu, keep main menu active
    setActiveSubmenu(null);
  };

  return (
    <header className="header" id="header">
      <nav className="navbar container navbar-auto">
        <section className="navbar-left">
          <Link to="/" className="brand desktop">
            <img src={logoDesktop} alt="Pharmaline" title="Pharmaline" width="240" height="75" />
          </Link>
          <Link to="/" className="brand mobile">
            <img src={logoMobile} alt="Pharmaline" title="Pharmaline" width="240" height="75" />
          </Link>
          <Link to="/" className="brand mobileSm">
            <img src={logoMobileSm} alt="Pharmaline" title="Pharmaline" width="70" height="70" />
          </Link>
        </section>

        <section className="navbar-center">
          <div className={`menu ${isMenuActive ? 'is-active' : ''}`} id="menu">
            <div 
              className={`menu-header ${activeSubmenu ? 'is-active' : ''}`}
              onClick={handleMenuHeaderClick}
            >
              <span className="menu-arrow"><i className="icon-rightArrow"></i></span>
              <span className="menu-title">{activeSubmenu}</span>
            </div>

            <ul className="menu-inner">
              {/* About Us Dropdown */}
              <li 
              className={`menu-item menu-dropdown ${activeSubmenu === 'About us' ? 'is-active' : ''}`}
              onMouseEnter={isDesktop ? () => setActiveSubmenu('About us') : undefined}
              onMouseLeave={isDesktop ? () => setActiveSubmenu(null) : undefined}
              onClick={() => !isDesktop && toggleSubmenu('About us')}
                // className={`menu-item menu-dropdown ${activeSubmenu === 'About us' ? 'is-active' : ''}`}
                // onClick={() => toggleSubmenu('About us')}
              >
                <span className="menu-link">
                  About us <i className="icon-downarrow"></i>
                </span>
                {activeSubmenu === 'About us' && (
                  <div className="submenu megamenu container is-active">
                    <div className="row p-2 m-0">
                      <div className="col-12 col-lg-4">
                        <div className="AboutCol">
                          <h2>About us</h2>
                          <p>Pharmaline Inc is a Nutraceutical and Supplement manufacturer specializing in full service private label manufacturing, packaging as well as exporting our brands worldwide.</p>
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="AboutCol">
                          <ul>
                            <li><Link to="/overview">Overview</Link></li>
                            <li><Link to="/contactus">Contact us</Link></li>
                            <li><Link to="/career">Career</Link></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="ProfileView AboutCol">
                          <img src={profilePic} alt="Profile" title="Profile" />
                          <p>Suresh Kolla</p>
                          <p>Founder, CEO</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </li>

              {/* Products Dropdown */}
              <li 
                className={`menu-item menu-dropdown ${activeSubmenu === 'Products' ? 'is-active' : ''}`}
                onMouseEnter={isDesktop ? () => setActiveSubmenu('Products') : undefined}
                onMouseLeave={isDesktop ? () => setActiveSubmenu(null) : undefined}
                onClick={() => !isDesktop && toggleSubmenu('Products')}
                // className={`menu-item menu-dropdown ${activeSubmenu === 'Products' ? 'is-active' : ''}`}
                // onClick={() => toggleSubmenu('Products')}
              >
                <span className="menu-link">
                  Products <i className="icon-downarrow"></i>
                </span>
                {activeSubmenu === 'Products' && (
                  <div className="submenu megamenu container is-active" >
                    <ul className="menuProductList">
                      {productMenu.map((product, index) => (
                        <li key={index}>
                          <Link to={`/${product.href}`}>
                            <div className="productIcon">
                              {product.icon ? (
                                <i className={product.icon}></i>
                              ) : (
                                <i>
                                  <img src={product.svgIcon} className="menuSVG" alt={product.text} />
                                </i>
                              )}
                            </div>
                            <div className="productText">{product.text}</div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <div className="row">
                      <div className="col-12 col-lg-12 text-center">
                        <Link to="/productsList" className="redBtn col-12 col-lg-3">
                          View all
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </li>

              {/* Manufacturing Services Dropdown */}
              <li 
                className={`menu-item menu-dropdown ${activeSubmenu === 'Manufacturing services' ? 'is-active' : ''}`}
                onMouseEnter={isDesktop ? () => setActiveSubmenu('Manufacturing services') : undefined}
                onMouseLeave={isDesktop ? () => setActiveSubmenu(null) : undefined}
                onClick={() => !isDesktop && toggleSubmenu('Manufacturing services')}
                // className={`menu-item menu-dropdown ${activeSubmenu === 'Manufacturing services' ? 'is-active' : ''}`}
                // onClick={() => toggleSubmenu('Manufacturing services')}
              >
                <span className="menu-link">
                  Manufacturing services <i className="icon-downarrow"></i>
                </span>
                {activeSubmenu === 'Manufacturing services' && (
                  <div className="submenu megamenu w-50 manufacturingMenu_megamenu is-active" >

                    <div className="manufacturingMenu">
                      <div className="row">
                        <div className="col-12 col-lg-12">
                          <ul className="nav nav-tabs manufacturingTabsMenu" id="manufacturingSubMenu" role="tablist">
                            {[
                              { name: 'Capsules', link: '/manufacturingservices/#capsules' },
                              { name: 'Tablets', link: '/manufacturingservices/#tablets' },
                              { name: 'Tablet Coating', link: '/manufacturingservices/#tablet-coating' },
                              { name: 'Pouches / sachet / stick pack', link: '/manufacturingservices/#blending' },
                              { name: 'Contract Manufacturing', link: '/manufacturingservices/#contract-manufacturing' }
                            ].map((service, index) => (
                              <li key={index} className="nav-item" role="manufacturing">
                                <Link to={service.link} className="nav-link">
                                  {service.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </li>
              {/* Packaging Services Dropdown */}
              <li 
                className={`menu-item menu-dropdown ${activeSubmenu === 'Packaging services' ? 'is-active' : ''}`}
                onMouseEnter={isDesktop ? () => setActiveSubmenu('Packaging services') : undefined}
                onMouseLeave={isDesktop ? () => setActiveSubmenu(null) : undefined}
                onClick={() => !isDesktop && toggleSubmenu('Packaging services')}
                // className={`menu-item menu-dropdown ${activeSubmenu === 'Packaging services' ? 'is-active' : ''}`}
                // onClick={() => toggleSubmenu('Packaging services')}
              >
                <span className="menu-link">
                  Packaging services <i className="icon-downarrow"></i>
                </span>
                {activeSubmenu === 'Packaging services' && (
                  <div className="submenu megamenu w-50 packagingServicesMenu_megamenu is-active" >
                  <div className="manufacturingMenu">
                    <div className="row">
                      <div className="col-12 col-lg-12">
                        <ul className="nav nav-tabs manufacturingTabsMenu" id="manufacturingSubMenu" role="tablist">
                          {[
                            { service: 'Blister Packaging', link: '/packagingservices/#blister-packing' }, 
                            { service: 'Powder filling', link: '/packagingservices/#powder-filling' }, 
                            { service: 'Bottling', link: '/packagingservices/#bottling' }
                          ].map((item, index) => (
                            <li key={index} className="nav-item" role="manufacturing">
                              <Link to={item.link} className="nav-link">
                                {item.service}
                              </Link>
                            </li>
                          ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </li>

              {/* Catalog Dropdown */}
              <li 
                className={`menu-item menu-dropdown ${activeSubmenu === 'Catalog' ? 'is-active' : ''}`}
                onMouseEnter={isDesktop ? () => setActiveSubmenu('Catalog') : undefined}
                onMouseLeave={isDesktop ? () => setActiveSubmenu(null) : undefined}
                onClick={() => !isDesktop && toggleSubmenu('Catalog')}
                // className={`menu-item menu-dropdown ${activeSubmenu === 'Catalog' ? 'is-active' : ''}`}
                // onClick={() => toggleSubmenu('Catalog')}
              >
                <span className="menu-link">
                  Catalog <i className="icon-downarrow"></i>
                </span>
                {activeSubmenu === 'Catalog' && (
                  <div className="submenu megamenu catlogMenu_magamenu is-active"  >
                    <div className="manufacturingMenu">
                      <div className="row">
                        <div className="col-12">
                          <div className="tab-pane fade show active" id="ProductListMenu" role="tabpanel">
                            <div className="rightsideImg">
                              <img src={catalogImg} alt="" title="" className="CatlogImage" />
                              <p className="text-center">
                                {/* <a href="https://online.pubhtml5.com/ynyys/wpbd/" target="_blank" className="redBtn">
                                  Download <i className="icon-arrowlinedown"></i>
                                </a> */}
                                <Link to="/broucher" className="redBtn">
                                  Download
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </section>
         
        <section className="navbar-right">
          <Link to="#" className="navSearch search-toggle" data-selector="#header"></Link>
          <div 
            className="burger" 
            id="burger" 
            onClick={toggleMenu}
          >
            <span
              className={isMenuActive ? "icon-powder" : "icon-textindent"} 
              onClick={toggleMenu}
            />

          </div>
          
        </section>
      </nav>
    </header>
  );
};

export default Navbar;